import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import generalService from "../services/General";

export const help = createAsyncThunk("general/help", async (formDate) => {
  try {
    const data = await generalService.help(formDate);
    return data;
  } catch (error) {}
});


export const emailSubscribe = createAsyncThunk("general/subscribe", async (formDate) => {
  try {

    const data = await generalService.subscribe(formDate);
    return data;
  } catch (error) {}
});


const generalSlice = createSlice({
  name: "general",
  initialState: null,
});

const { reducer } = generalSlice;
export default reducer;
