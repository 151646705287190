import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import PostService from "../services/Post";
import { postModelIncoming, postModelOutcoming } from "../models/post";
import { async } from "q";
import { postModelDetailedIncoming } from "../models/post";
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  status: false,
  posts: [],
  error: "",
  loading: true,
  postDetail: "",
};

export const getposts = createAsyncThunk(
  "post/getposts",
  async ({ offset, limit, type, searchText, sort }) => {
    if (type === "all") {
      type = "";
    }
    try {
      const data = await PostService.getposts({
        offset,
        limit,
        type,
        searchText,
        sort,
      });
      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const fetchPostBySlug = createAsyncThunk(
  "post/fetchPostBySlug",
  async (slug) => {
    try {
      const post = await PostService.fetchPostBySlug(slug);
      return post === null ? null : postModelDetailedIncoming(post);
      return post;
    } catch (error) {
      return { error };
    }
  }
);

const postSlice = createSlice({
  name: "post",
  initialState,
  extraReducers: {
    [getposts.fulfilled]: (state, action) => {
      state.status = true;
      state.posts.push(action.payload);
      state.error = null;
      state.loading = false;
    },

    [getposts.rejected]: (state, action) => {
      state.status = true;
      state.posts = null;
      state.error = action.payload;
    },
    [fetchPostBySlug.fulfilled]: (state, action) => {
      state.status = true;
      state.postDetail = action.payload;
      state.error = null;
      state.loading = false;
    },
    [fetchPostBySlug.rejected]: (state, action) => {
      state.status = true;
      state.postDetail = null;
      state.error = action.payload;
    },
  },
});

export const createPost = createAsyncThunk(
  "post/createpost",
  async ({
    title,
    description,
    type,
    postAddress,
    anonymously,
    media,
    url,
    gif,
    privacy,
    externalVideoUrl,
    isFound,
    initialQuestion,
    newTopicName,
    reporterLocation,
    needsCaption,
    speechLanguages,
  }) => {
    try {
      const adjustedPost = postModelOutcoming(
        title,
        description,
        type,
        postAddress,
        anonymously,
        media,
        url,
        gif,
        privacy,
        externalVideoUrl,
        isFound,
        initialQuestion,
        newTopicName,
        reporterLocation,
        needsCaption,
        speechLanguages
      );
      const data = await PostService.pushPost(adjustedPost);
      return data;
    } catch (error) {
      return false;
    }
  }
);

const { reducer } = postSlice;
export default reducer;
