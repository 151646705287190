import { axiosInstance } from "../config/network";

const help = (formData) => {
  return axiosInstance
    .post("/contact-us/", {
      email: formData.email,
      name: formData.name,
      message: formData.message,
      category: parseInt(formData.category),
    })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.error,
      };
    });
};

const subscribe = (formData) => {
  console.log(formData.email);
  return axiosInstance
    .post("/user/subscribe/", { email: formData.email })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
        };
      } else {
        return { status: false, error: res.data.email };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.email[0],
      };
    });
};

const generalService = {
  help,
  subscribe,
};

export default generalService;
