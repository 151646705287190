import React, { useEffect } from "react";

const TermsContentComponent = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  });
  return (
    <div className=" min-vh-100" style={{ marginTop: "60px" }}>
      <div className="pt-5 container">
        <h1 className=" static-title  ">Terms and Conditions</h1>
        <hr />
      </div>
      <div id="terms-content" className="container">
        <p>
          Welcome to the WYSPP website (the “Site”), mobile application(s) (the
          “App”) and all affiliated content (collectively, “WYSPP”). This Terms
          and Conditions (this “Agreement”) is between You (hereinafter, “You”,
          “Your”, “User”) and WYSPP. By accessing and using WYSPP, its mobile
          applications, its website, and/or using the services or goods offered
          on or through WYSPP (the“Services”), You agree to this Agreement as it
          may be amended from time-to-time. Please read this Agreement carefully
          and keep a copy for Your reference.
        </p>
        <p>
          NOTE: WYSPP MAINTAINS A ZERO TOLERANCE POLICY FOR OBJECTIONABLE
          CONTENT AND/OR ABUSIVE USERS ON ITS PLATFORMS AND/OR SERVICES. PLEASE
          READ SECTION 6.0.0 OF THIS AGREEMENT TITLED ‘PROHIBITED USE OF WYSPP
          SERVICES’ FOR MORE INFORMATION. IF YOU INTEND TO EXPLOIT OR ABUSE
          WYSPP SERVICES IN VIOLATION OF THIS AGREEMENT, THEN WYSPP KINDLY ASKS
          YOU TO REFRAIN FROM CREATING AN ACCOUNT WITH OR USING WYSPP SERVICES;
          OTHERWISE, YOU ARE KNOWINGLY VIOLATING THIS AGREEMENT WHICH MAY
          SUBJECT YOU TO ENFORCEMENT ACTION AND/OR LEGAL ACTION BY WYSPP AT ITS
          SOLE DISCRETION.
        </p>
        <h1>1.0.0 Purpose.</h1>
        <p>
          The purpose of WYSPP is to provide a news and information sharing
          website and application which allows Users to broadcast news and
          events worldwide. The Website, all Services, and all Content (meaning
          all material on the Website or offered through the Services,
          including, without limitation, all images, text, audio, webinars,
          downloads, applications, articles, photographs, and their selection
          and arrangement) are provided for public use and accessibility to the
          Users.
        </p>
        <h1>2.0.0 Privacy Policy.</h1>
        <p>
          Your use of WYSPP is subject to WYSPP’s Privacy Policy. Please review
          WYSPP’s Privacy Policy, which also governs the Site and the App and
          informs Users of our data collection practices.
        </p>
        <h1>3.0.0 Electronic Communications.</h1>
        <p>
          Visiting the Site, using the App, or sending emails, data, or content
          to WYSPP constitutes electronic communications. By creating an account
          with WYSPP, You affirmatively consent to receive electronic
          communications and You agree that all agreements, notices, disclosures
          and other communications that we provide to You electronically, via
          email and on the Site or App, satisfy any legal requlrement that such
          communications be in writing.
        </p>
        <h1>4.0.0 Creating Your Account.</h1>
        <p>
          By creating an account with WYSPP, You affirmatively consent that if
          You use this site, You are responsible for maintaining the
          confidentiality of Your account and password and for restricting
          access to Your computer, and You agree to accept responsibility for
          all activities that occur under Your account or password. You may not
          assign or otherwise transfer Your account to any other person or
          entity. You acknowledge that WYSPP is not responsible for third party
          access to Your account that results from theft or misappropriation of
          Your account. WYSPP and its associates or affiliates reserve the right
          to refuse or cancel service terminate accounts, or remove or edit
          content in their sole discretion.
        </p>
        <h1>5.0.0 No Unlawful or Prohibited Use/Intellectual Property.</h1>
        <p>
          You are granted a non-exclusive, non-transferable, revocable license
          to access and use the Site and the App and all offerings of WYSPP
          strictly in accordance with these terms of use. As a condition of Your
          use of the Site and the App, You warrant to WYSPP that You will not
          use the Site or the App for any purpose that is unlawful under the
          laws of Your jurisdiction or prohibited by these Terms. You may not
          use the Site or the App in any manner which could damage, disable,
          overburden, or impair the Site or the App or interfere with any other
          party’s use and enjoyment of the Site or the App. You may not obtain
          or attempt to obtain any materials or information through any means
          not intentionally made available or provided for through the Site or
          the App. You may not provide false information when signing up for
          WYSPP and You may not use WYSPP in any way which invades others’
          privacy including recording private interactions without the consent
          of all parties requlred under any applicable laws. All content, such
          as text, graphics, logos, images, as well as the compilation thereof,
          and any software used on the Site, or the App, is the property of
          WYSPP and protected by copyright and other laws that protect
          intellectual property and proprietary rights. You agree to observe and
          abide by all copyright and other proprietary notices, legends or other
          restrictions contained in any such content and will not make any
          changes thereto. You will not attempt to/or modify, publish, transmit,
          reverse engineer, participate in the transfer or sale, create
          derivative works, or in any way exploit any of the content, in whole
          or in part, found on the Site or the App. Such content is not for
          resale. Your use of the Site or the App does not entitle You to make
          any unauthorized use of any protected content, and in particular, You
          will not delete or alter any proprietary rights or attribution notices
          in any content. You will use protected content solely for Your
          personal use and will make no other use of the content without the
          express written permission of WYSPP and the copyright owner. You agree
          that You do not acqulre any ownership rights in any protected content.
          WYSPP does not grant You any licenses, express or implied, to the
          intellectual property of WYSPP or their licensors except as expressly
          authorized by these Terms.
        </p>
        <h1>6.0.0 Prohibited Use of WYSPP Services.</h1>
        <p>
          WYSPP maintains a zero-tolerance policy for any User postings,
          behavior, or actions that convey violence, harassment, and other
          behavior that discourage other Users from engaging in WYSPP’s
          Services. By creating an account with WYSPP, you expressly agree to
          abide by these terms of use and subject your content, profile, and
          postings to WYSPP enforcement action for violating this Agreement. For
          clarification or preapproval of any content postings, please email
          WYSPP at policy@wyspp.com with the subject line “User Content
          Posting”. Therefore, You expressly agree to refrain from the following
          behavior so that other Users of WYSPP may freely engage in the use and
          enjoyment of WYSPP Services:
        </p>
        <h1>
          6.0.1 Sensitive Media, including Graphic Violence and Adult Content.
        </h1>
        <p>
          You may not post any media content that contains graphic violence that
          is not for journalistic, documentary, or educational purposes, nor can
          You post any adult content that is pornographic in nature or intended
          to arouse other Users unless the adult content is journalistic,
          documentary, artistic, medical, health, or educational purposes.
          Furthermore, under no circumstances may You post any content that
          depicts violence, whether real or simulated, in association with
          sexual acts; any content that depicts excessively graphic or gruesome
          content related to death, violence or severe bodily harm, or sadistic
          purposes; and any content that contains hateful imagery, such as a
          logo, symbol, or image, with the intent to promote violence and
          hostility to other Users or people based on race, religion,
          disability, national origin, gender, or sexual orientation.
        </p>
        <h1>6.0.2 Violence/Abuse/Harassment.</h1>
        <p>
          You may not engage in any targeted harassment of another User(s) or
          threaten violence against another User, person, or group of people,
          nor can you incite another person to do so. Furthermore, You may not
          encourage, promote, or glorify any acts of violence, such as those
          that express a will or intent to inflict death or great bodily harm to
          another. This section further prohibits You from posting content that
          threatens or promotes terrorism or violent extremism, or promotes or
          supports groups and organizations that are known to have a record of
          extreme violence and/or systematic hatred and violence towards
          particular persons,
        </p>
        <h1>6.0.3 Child Sexual Exploitation.</h1>
        <p>
          WYSPP has zero tolerance for any postings that depict or promote child
          sexual exploitation.
        </p>

        <h1>6.0.4 Hateful Conduct.</h1>
        <p>
          You may not promote violence against or directly attack or threaten
          other people or Users on the basis of race, ethnicity, national
          origin, caste, sexual orientation, gender, gender identity, religious
          affiliation, age, disability, or serious disease. We also do not allow
          accounts whose primary purpose is inciting harm towards others on the
          basis of these categories. You may not use hateful images or symbols
          in your profile image or profile header. You also may not use your
          username, display name, or profile bio to engage in abusive behavior,
          such as targeted harassment or expressing hate towards a person,
          group, or protected category.
        </p>

        <h1>6.0.5 Sulcide or Self-Harm.</h1>
        <p>You may not promote or encourage sulcide or self-harm.</p>

        <h1>6.0.6 Illegal or Certain Regulated Goods or Services.</h1>
        <p>
          You may not post any content that depicts illegal goods or services
          including counterfeit goods and services; drugs and controlled
          substances; human trafficking; sexual services; stolen goods; weapons;
          and products made from endangered species; unless such posting is for
          journalistic, documentary, or educational purposes.
        </p>

        <h1>
          6.0.7 Denying the Occurrence of an Event or Spoofing Geolocation.
        </h1>
        <p>
          WYSPP only allows Users within a predetermined geographic location of
          a happened or witnessed event to report the occurrence of an event.
          WYSPP may verify that the event took place such. Any content posted
          with the intent to undermine the occurrence of the event or label the
          event as a ‘hoax’, ‘staged’, or having ‘paid actors’, will be
          considered abusive behavior. Furthermore, any spoofing or altering the
          geolocation of the User to allow the User to post about a witnessed
          event as if the User was in the location of the event or witnessed the
          event will be considered abusive behavior.
        </p>

        <h1>6.1.0 Enforcement.</h1>
        <p>
          WYSPP’s goal is to provide a service where Users can record any
          witnessed event worth noting with photos and videos within a
          predetermined geographic location of the witnessed event without
          worrying about negative commentary and/or dissenting opinions. WYSPP
          further provides Users with an investigative tool to aid in
          investigative journalism to find meaning and origins to unknown
          objects or pictures from anywhere in the world. Moreover, WYSPP
          provides a hyper-localized lost and found for its Users, beyond the
          neighborhood, and without the noise of social media and news feeds.
          Therefore, to protect WYSPP and its Users, WYSPP may take enforcement
          action, in its sole discretion, against a User for any objectionable
          behavior or actions under this Section. Such enforcement action may
          include, but is not limited to:
        </p>
        <ul>
          <li>
            Labeling a post that may contain disputed or misleading information.
          </li>
          <li>Limiting post visibility to other Users.</li>
          <li>Removing a post in its entirety.</li>
          <li>
            Temporarily hiding a post from public visibility while WYSPP reviews
            its content for a violation of this Agreement.
          </li>
          <li>Requlring media or profile edits.</li>
          <li>
            Placing an account on read-only mode, of which the User may not post
            content which is available to public view.
          </li>
          <li>Requlre verification of account ownership.</li>
          <li>
            Requlre verification of account geolocation with regards to a post.
            and/or
          </li>
          <li>Permanently suspend an account.</li>
        </ul>

        <h1>
          6.1.1 Factors to Determine Enforcement Action. Although WYSPP may in
          its sole discretion take any enforcement action that it deems
          necessary and proper in carrying out the terms of this Agreement,
          WYSPP may consider numerous factors in determining the severity of
          enforcement actions including, but not limited to:
        </h1>

        <ul>
          <li>
            the behavior is directed at an individual, group, or protected
          </li>
          <li> category of people;</li>
          <li>
            the report has been filed by the target of the abuse or a bystander;
          </li>
          <li>the User has a history of violating our policies;</li>
          <li> the severity of the violation;and</li>
          <li> the content may be a topic of legitimate public interest.</li>
        </ul>

        <h1>6.1.2 Disputing an Enforcement Action.</h1>

        <p>
          Any User may dispute an enforcement action taken by WYSPP by emailing
          WYSPP at policy@wyspp.com having the subject of the email as “Dispute
          – Zero Tolerance” and the body of the email containing a brief
          description of the reasons why WYSPP should not take enforcement
          action or reduce the severity of the enforcement action. WYSPP may
          take any dispute into consideration at its sole discretion.
        </p>

        <h1>7.0.0 Children Under Thirteen.</h1>

        <p>
          WYSPP does not knowingly collect, either online or offline, personal
          information from persons under the age of thirteen. If You are under
          18, You may use the Site or the App only with permission and
          supervision of a parent or guardian. Please see Section 7.0.0 of the
          Privacy Policy for more information regarding the Children’s Online
          Privacy Protection Act.
        </p>

        <h1>8.0.0 Links to Third Party Sites/Third Party Services.</h1>

        <p>
          The Site or App may contain links to other websites (“Linked Sites”).
          The Linked Sites are not under the control of WYSPP and WYSPP is not
          responsible for the contents of any Linked Site, including without
          limitation any link contained in a Linked Site, or any changes or
          updates to a Linked Site. WYSPP is providing these links to You only
          as a convenience, and the inclusion of any link does not imply
          endorsement by WYSPP of the site or any association with its
          operators. Certain services of WYSPP or made available via the Site or
          App are delivered by third party sites and organizations. By creating
          an account with WYSPP, You affirmatively consent that by using any
          product, service or functionality originating from the Site or App,
          that WYSPP may share such information and data with any third party
          with whom WYSPP has a contractual relationship to provide the
          requested product, service or functionality on behalf of its Users and
          customers. For more information regarding WYSPP’s use of Your
          information, please read the Privacy Policy.
        </p>

        <h1>9.0.0 International Users.</h1>
        <p>
          The Service is controlled, operated and administered by WYSPP from our
          offices in the State of Florida, United States of America. If You
          access the Service from a location outside the USA, You are
          responsible for compliance with all national, state and local laws.
          You agree that You will not use the WYSPP content accessed through the
          Site or the App, or upload or publish content on the Site or the App,
          in any country or in any manner prohibited by any applicable laws,
          restrictions or regulations. For more information about WYSPP
          compliance with international data protection, privacy, and regulation
          laws please visit the Privacy Policy.
        </p>

        <h1>10.0.0 Copyright Infringement and Potential DMCA Violations.</h1>

        <p>
          WYSPP values the copyright and other rights of all persons and legal
          entities. If You believe that any posting on the Site or the App by
          any User violates Your intellectual property rights, please notify
          WYSPP’s copyright agent. For Your complaint to be valid under the
          Digital Millennium Copyright Act (“DMCA”), You must provide the
          following information and verification:
        </p>
        <ul>
          <li>
            The electronic or physical signature of a natural person who is
            responsible for the complaint and is acting on behalf of the
            copyright owner;
          </li>
          <li>
            An identification of the Copyrighted Work that You content has been
            infringed;
          </li>

          <li>
            Identification of the allegedly infringing material including the
            name of the User who uploaded the allegedly infringing material, and
            the title of the upload, and the date and time uploaded;
          </li>

          <li>
            Information reasonably sufficient for WYSPP to contact You to
            discuss Your complaint, including a telephone number, physical
            address, and email address;
          </li>

          <li>
            A statement that You have a good faith belief that the use of the
            copyrighted material is not authorized by the copyright owner, any
            authorized agent, or applicable copyright law, including without
            limitation the “fair use” doctrine; and
          </li>

          <li>
            A statement under penalty of perjury that the above information is
            accurate and that You are the copyright owner or someone authorized
            to act on their behalf.
          </li>
        </ul>

        <p>Submit to the following:</p>
        <p> WYSPP</p>
        <p> DMCA Agent </p>
        <p> policy@wyspp.com</p>

        <p>
          NOTE: UNDER FEDERAL LAW YOU MAY BE LIABLE FOR KNOWINGLY
          MISREPRESENTING THAT ONLINE MATERIAL IS INFRINGING, WHICH LIABILITY
          INCLUDES POTENTIAL CRIMINAL CHARGES FOR PERJURY AS WELL AS CIVIL
          PENALTIES AND ATTORNEYS’ FEES. MISUSE OF THIS FEATURE MAY RESULT IN
          TERMINATION OF YOUR ACCOUNT.
        </p>

        <h1>10.1.0 Counter-Notification Response to DMCA Complaint</h1>

        <p>
          If You believe that any material You posted as a User has been
          wrongfully removed from the Site or the App, You may provide a written
          counter-notification under the DMCA which must include the following
          information:
        </p>
        <ul>
          <li>
            Your name, address, telephone number, and email address, if any;
          </li>
          <li>
            A description of the material removed and the location on the Site
            or the App or the title of the posting(s), and the time and date it
            was uploaded;
          </li>

          <li>
            A statement under penalty of perjury that You have a good faith
            belief that the material which was removed or disabled was removed
            or disabled as a result of a mistake or misidentification;
          </li>

          <li>
            A statement that You consent to the jurisdiction of the Federal
            District Court in which WYSPP is located, or in which You are
            located, or if You are located outside the United States, in any
            jurisdiction for which You may be legally served with process, and
            that You will accept service of process by the person making the
            original complaint or an agent of that person or entity; and
          </li>

          <li>Your electronic or physical signature.</li>
        </ul>

        <h1>
          11.0.0 User Rights in Content Uploaded or Streamed to Site or App.
        </h1>

        <p>
          Nothing in these Terms interferes with Your ownership of all legal,
          non-infringing content uploaded by You as a User to WYSPP.
        </p>

        <h1>
          12.0.0 WYSPP and Other Users’ Limited License to Use or Comment.
        </h1>

        <p>
          When You provide User Content to WYSPP through the Site or the App,
          You grant WYSPP a non-exclusive, universal, worldwide, royalty-free,
          sublicensable, transferable right and license to use, host, store,
          cache, reproduce, publish, display (publicly or otherwise), perform
          (publicly or otherwise), distribute, transmit, modify, adapt
          (including, without limitation, in order to conform it to the
          requirements of any networks, devices, services, or media through
          which the Site or App are available), and create derivative works of,
          such User Content. The rights You grant in this license are for the
          limited purposes of allowing WYSPP to operate the Site and the App in
          accordance with their functionality, improve and promote the Site and
          the App, and develop new features. The reference in this license to
          “creat[ing] derivative works” is not intended to give WYSPP a right to
          make substantive editorial changes or derivations, but does, for
          example, enable commenting or reposting by other Users to redistribute
          User Content from one User Account to another in a manner that allows
          them to add their own text or other User Content before or after Your
          User Content.
        </p>

        <h1>13.0.0 Indemnification.</h1>

        <p>
          You agree to indemnify, defend and hold harmless WYSPP, its officers,
          directors, employees, agents and third parties, for any losses, costs,
          liabilities and expenses (including reasonable attorney’s fees)
          relating to or arising out of Your use of or inability to use the
          Site, the App, or services, any User postings made by You, Your
          violation of any terms of this Agreement or Your violation of any
          rights of a third party, or Your violation of any applicable laws,
          rules or regulations. WYSPP reserves the right, at its own cost, to
          assume the exclusive defense and control of any matter otherwise
          subject to indemnification by You, in which event You will fully
          cooperate with WYSPP in asserting any available defenses.
        </p>

        <h1>14.0.0 Breach of Terms of Service.</h1>

        <p>
          In any action, proceeding, or litigation relating to, connected with,
          or arising out of this Agreement, the prevailing party shall be
          entitled to reimbursement of its costs, including attorney’s fees,
          incurred through all trial, appellate, and post-judgment proceedings,
          if any. Should this agreement be breached by either party, said
          breaching party shall be given 7 calendar days to cure the breach of
          this agreement.
        </p>

        <h1>15.0.0 Arbitration.</h1>
        <p>
          In the event the parties are not able to resolve any dispute between
          them arising out of or concerning these Terms and Conditions, or any
          provisions hereof, whether in contract, tort, or otherwise at law or
          in equity for damages or any other relief, then such dispute shall be
          resolved only by final and binding arbitration pursuant to the Federal
          Arbitration Act, conducted by a single neutral arbitrator and
          administered by the American Arbitration Association, or a similar
          arbitration service selected by the parties, in a location mutually
          agreed upon by the parties in Florida. The arbitrator’s award shall be
          final, and judgment may be entered upon it in any court having
          jurisdiction. If any legal or equitable action, proceeding or
          arbitration arises out of or concerns these Terms and Conditions, the
          prevailing party shall be entitled to recover its costs and reasonable
          attorney’s fees. The parties agree to arbitrate all disputes and
          claims regarding these Terms and Conditions or any disputes arising as
          a result of these Terms and Conditions, whether directly or
          indirectly, including Tort claims that are a result of these Terms and
          Conditions. The parties agree that the Federal Arbitration Act governs
          the interpretation and enforcement of this provision. The entire
          dispute, including the scope and enforceability of this arbitration
          provision shall be determined by the Arbitrator. This arbitration
          provision shall survive the termination of these Terms and Conditions.
        </p>

        <h1>16.0.0 Class Action Waiver.</h1>
        <p>
          Any arbitration under these Terms and Conditions will take place on an
          individual basis; class arbitrations and
          class/representative/collective actions are not permitted. THE PARTIES
          AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH’S
          INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS
          IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.
          Further, unless both You and WYSPP agree otherwise, the arbitrator may
          not consolidate more than one person’s claims, and may not otherwise
          preside over any form of a representative or class proceeding.
        </p>

        <h1>17.0.0 Liability Disclaimer.</h1>
        <p>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
          AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
          ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
          WYSPP AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE
          SITE OR APP AT ANY TIME. WYSPP AND/OR ITS SUPPLIERS MAKE NO
          REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
          TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
          SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE OR IN THE APP FOR
          ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL
          SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
          ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. WYSPP
          AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS
          WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
          RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
          NON-INFRINGEMENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
          IN NO EVENT SHALL WYSPP AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
          DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
          USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
          USE OR PERFORMANCE OF THE SITE OR THE APP, WITH THE DELAY OR INABILITY
          TO USE THE SITE, THE APP, OR RELATED SERVICES, THE PROVISION OF OR
          FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
          PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE OR
          THE APP, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER
          BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
          EVEN IF WYSPP OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
          POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
          INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
          ARE DISSATISFIED WITH ANY PORTION OF THE SITE OR THE APP, OR WITH ANY
          OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
          DISCONTINUE USING THE SITE AND/OR THE APP.
        </p>

        <h1>18.0.0 Termination/Access Restriction.</h1>

        <p>
          WYSPP reserves the right, in its sole discretion, to terminate Your
          access to the Site and the App and the related services or any portion
          thereof at any time, without notice. To the maximum extent permitted
          by law, this agreement is governed by the laws of the State of Florida
          and You hereby consent to the exclusive jurisdiction and venue of
          courts in Florida in all disputes arising out of or relating to the
          use of the Site or the App. Use of the Site or the App is unauthorized
          in any jurisdiction that does not give effect to all provisions of
          these Terms, including, without limitation, this section. You agree
          that no joint venture, partnership, employment, or agency relationship
          exists between You and WYSPP resulting from this agreement or use of
          the Site or the App. WYSPP’s performance of this agreement is subject
          to existing laws and legal process, and nothing contained in this
          agreement is in derogation of WYSPP’s right to comply with
          governmental, court and law enforcement requests or requirements
          relating to Your use of the Site or the App or information provided to
          or gathered by WYSPP with respect to such use. If any part of this
          agreement is determined to be invalid or unenforceable pursuant to
          applicable law including, but not limited to, the warranty disclaimers
          and liability limitations set forth above, then the invalid or
          unenforceable provision will be deemed superseded by a valid,
          enforceable provision that most closely matches the intent of the
          original provision and the remainder of the agreement shall continue
          in effect. Unless otherwise specified herein, this agreement
          constitutes the entire agreement between the User and WYSPP with
          respect to the Site and the App and it supersedes all prior or
          contemporaneous communications and proposals, whether electronic, oral
          or written, between the User and WYSPP with respect to the Site and
          the App. A printed version of this agreement and of any notice given
          in electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to this agreement to the same
          extent and subject to the same conditions as other business documents
          and records originally generated and maintained in printed form. It is
          the express wish to the parties that this agreement and all related
          documents be written in English.
        </p>

        <h1>19.0.0 Changes to Terms of this Agreement.</h1>

        <p>
          WYSPP reserves the right, in its sole discretion, to change the Terms
          under which WYSPP’s materials and access to the Site and the App are
          offered. The most current version of the Terms will supersede all
          previous versions. WYSPP encourages You to periodically review the
          Terms to stay informed of our updates.
        </p>

        <h1>20.0.0 Contact Us.</h1>

        <p>
          WYSPP welcomes Your questions or comments regarding these Terms and
          Condition at: policy@wyspp.com
        </p>

        <h1>Effective as of September 1, 2021</h1>
      </div>
    </div>
  );
};

export default TermsContentComponent;
