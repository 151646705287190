

import { axiosInstance } from "../config/network";

const sendFile =  (file) => {
  const data = new FormData();
  data.append("file", file);
  return axiosInstance
    .post("/upload/", data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          url: res.data.data,
        };
      } else {
        return { status: false, error: res.data.data.file };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.file,
      };
    });
};

const uploadService = {
  sendFile,
};
export default uploadService;



    
  

