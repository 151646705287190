import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { help } from "../../../../../slices/general";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useForm } from "react-hook-form";

const HelpComponent = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  });
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm();

  const dispatch = useDispatch();

  const onErrors = (errors) => console.error(errors);

  const onFormSubmit = (formValue) => {
    dispatch(help(formValue))
      .unwrap()
      .then((value) => {
        if (value.status !== true) {
          setError("help", { type: "custom", message: value.error });
        } else {
          navigate("/confirmation");
        }
      })
      .catch(() => {});
  };

  return (
    <div className="container">
      <div
        className="row mb-3 mt-5  d-flex align-items-md-center  flex-column justify-content-center"
        style={{ minHeight: "95vh" }}>
        <div className="support-form-container mt-5 pt-5 pb-5 px-md-5 pb-3 col-md-6 d-flex flex-column mx-md-5">
          <h1 className="welcome-text pb-4"> Help and Support</h1>

          <Form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
            <div className="pb-3 mx-3 ">
              <Form.Control
                {...register("name", {
                  required: "please enter your name.",
                })}
                type="text"
                id="name"
                className="input py-3"
                placeholder="Name"
              />
              <div className="text-danger mx-3">
                {errors?.name && errors.name.message}
              </div>
            </div>

            <div className="pb-3 mx-3 ">
              <Form.Control
                {...register("email", {
                  required: "please enter your  email.",
                  pattern: "^[w-.]+@([w-]+.)+[w-]{2,4}$",
                })}
                type="email"
                id="email"
                className="input py-3"
                placeholder="Email"
              />
              <div className="text-danger mx-3">
                {errors?.email && errors.email.message}
              </div>
            </div>

            <div className="form-group pb-3 mx-3">
              <textarea
                {...register("message", {
                  required: "please enter your  message.",
                })}
                className="form-control input py-3"
                id="message"
                placeholder="Message"
                rows="3"></textarea>
            </div>

            <div className="text-danger mx-3">
              {errors?.message && errors.message.message}
            </div>

            <div className="pb-3 mx-3 ">
              <select
                {...register("category", {})}
                className="form-select input py-3 form-control "
                type="select"
                id="category"
                title="How can we help you?">
                <option selected value="2">
                  Inquiry
                </option>
                <option value="1">Help</option>
              </select>
            </div>
            {errors?.help && (
              <div className="text-danger  py-1 px-1 mx-3" style={{}}>
                {errors.help.message !== "" &&
                errors.help.message !== null &&
                errors.help.message !== undefined
                  ? errors.help.message
                  : "Something went wrong"}
              </div>
            )}
            <div>
              <Button
                type="submit"
                className={"auth-submit-button mt-3  py-2 mx-3 "}
                disabled={!isDirty || !isValid}
                onClick={() => clearErrors("help")}>
                Send
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default HelpComponent;
