import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { businessModelIncoming } from "../models/bussiness";
import { inventoryModelIncoming } from "../models/inventory";
import businessService from "../services/Business";
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  status: false,
  business: [],
  error: "",
  loading: true,
};

export const getbusiness = createAsyncThunk(
  "business/getbusiness",
  async ({
    offset,
    limit,
    category,
    name,
    rating,
    priceLevel,
    sort,
    latitude,
    longitude,
  }) => {
    try {
      const data = await businessService.getbusiness({
        offset,
        limit,
        category,
        name,
        rating,
        priceLevel,
        sort,
        latitude,
        longitude,
      });
      // const x = data.business.map((b) => inventoryModelIncoming(b));

      return data;
    } catch (error) {
      return { error };
    }
  }
);


export const getBusinessCategories = createAsyncThunk(
  "business/getBusinessCategories",
  async () => {
    try {
      const data = await businessService.getCategories({});

      return data;
    } catch (error) {
      return { error };
    }
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState,
  extraReducers: {
    [getbusiness.fulfilled]: (state, action) => {
      state.status = true;
      state.business.push(action.payload);
      state.error = null;
      state.loading = false;
    },

    [getbusiness.rejected]: (state, action) => {
      state.status = true;
      state.business = null;
      state.error = action.payload;
    },
  },
});
const { reducer } = businessSlice;
export default reducer;
