import React, { useEffect } from "react";

const PrivacyContentComponent = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  });
  return (
    <div className=" min-vh-100" style={{ marginTop: "60px" }}>
      <div className="pt-5  container">
        <h1 className=" static-title  ">Privacy Policy</h1>
        <hr />
      </div>

      <div id="terms-content" className="container">
        <p>
          Protecting your private information is our priority. This Statement of
          Privacy applies to the WYSPP website and the WYSPP mobile application
          (collectively, “WYSPP”) and governs data collection and usage. By
          using WYSPP, you consent to the data practices described in this
          statement.
        </p>

        <h1>1.0.0 Collection of Your Personal Information.</h1>
        <p>
          To better provide you with products and services offered on our Site,
          WYSPP may collect personally identifiable information. While using
          WYSPP services, you may be required to provide certain personal or
          personalized information to us (such information referred to
          hereinafter as “Personal Information”). Our information collection and
          use policies with respect to the privacy of such Personal Information
          are set forth in this Privacy Policy. We encourage you to read the
          Privacy Policy, and to use it to help make informed decisions. You
          acknowledge and agree that you are solely responsible for the accuracy
          and content of Personal Information.
        </p>
        <h1>1.1.0 Definition of Personal Information.</h1>
        <p>
          Personal information means information that identifies, relates to,
          describes, is capable of being associated with, or could reasonably be
          linked, directly or indirectly, with a particular consumer or
          household. Personal information includes, but is not limited to, the
          following if it identifies, relates to, describes, is capable of being
          associated with, or could be reasonably linked, directly or
          indirectly, with a particular consumer or household:
        </p>
        <ul>
          <li>
            Identifiers such as a real name, alias, postal address, unique
            personal identifier, online identifier, Internet Protocol address,
            email address, account name social security number, driver’s license
            number, passport number, or other similar identifiers;
          </li>
          <li>
            Commercial information, including records of personal property,
            products or services purchased, obtained, or considered, or other
            purchasing or consuming histories or tendencies;
          </li>
          <li>Biometric Information;</li>

          <li>
            Internet or other electronic network activity information,
            including, but not limited to, browsing history, search history, and
            information regarding a consumer’s interaction with an Internet Web
            stie, application, or advertisement;
          </li>

          <li>Geolocation data;</li>

          <li>
            Audio, electronic, visual, thermal, olfactory, or similar
            information;
          </li>

          <li>Professional or employment related information;</li>

          <li>
            Education information, defined as information that is not publicly
            available personally identifiable information; and,
          </li>

          <li>
            Inferences drawn from any of the information to create a profile
            about a consumer reflecting the consumer’s preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </li>
        </ul>

        <h1>1.2.0 Personal Information Does Not Include.</h1>
        <p>
          Personal information does not include publicly available information.
          Publicly available means information that is lawfully made available
          from federal, state, or local government records, if any conditions
          associated with such information. “Publicly available” does not mean
          biometric information collected by a business about a customer without
          the customer’s knowledge. Information is not “publicly available” if
          that data is used for a purpose that is not compatible with the
          purpose for which data is maintained and made available in the
          government records or for which it is publicly maintained. “Publicly
          available” does not include consumer information that is deidentified
          or aggregate consumer information. Non-Personal Information may also
          include aggregated information, collectively analyzed from a variety
          of users’ personal information, such as the total number of times
          people engaged with a post, demographics, the number of people who
          clicked on a particular link or voted on a poll, the topics or events
          that people are engaging or posting about in a particular location,
          some inferred interests, aggregate location data including the number
          of active users within a particular geographic location, or reports to
          advertisers about how many people saw or clicked on their ads
          (“Aggregated Information”).
        </p>
        <h1>2.0.0 Information We Collect About You.</h1>
        <p>
          We collect the information that you voluntarily provide to us. For
          example,
        </p>
        <ul>
          <li>
            In order to access and/or use our Site and services (e.g., account
            registration, communication/networking features, purchases,
            reservations, promotions, etc.), we may require that you provide
            and/or otherwise make available to us certain information, which may
            include, among other things, your legal name, email address,
            physical address, telephone number, date of birth and gender.
          </li>

          <li>
            When you purchase products or services on our Site, we and/or our
            third-party payment processor will collect all information necessary
            to complete the transaction, including your name, billing
            information and shipping information.
          </li>

          <li>
            If you provide us feedback or contact us via email, we will collect
            your name and email address, as well as any other content included
            in the email, in order to send you a reply. When you post messages
            on our Site, the information contained in your posting will be
            stored on our servers and other users will be able to see it.
          </li>

          <li>
            When you participate in one of our surveys, we will collect the
            information you choose to submit to us.
          </li>

          <li>
            We also collect other types of Personal Information that you provide
            to us voluntarily, such as your operating system and version, and
            other requested information if you contact us via email regarding
            support for the Services.
          </li>

          <li>
            If you participate in a sweepstakes, contest, or giveaway on our
            Site, we may ask you for your email address, name, and phone number.
            These sweepstakes and contests are voluntary. We recommend that you
            read the rules for each sweepstakes and contest that you enter.
          </li>

          <li>
            We allow you to sign up and log in to the Site using accounts you
            create with third-party products and services, such as Facebook,
            Google, Twitter, Apple ID, and LinkedIn (collectively, “Third-Party
            Accounts”). If you access the Services with Third-Party Accounts, we
            will collect information that you have agreed to make available such
            as your name, email address, profile information and preferences
            with the applicable Third-Party Account. This information is
            collected by the Third-Party Account provider and is provided to us
            under their privacy policies. You can generally control the
            information that we receive from these sources using the privacy
            settings in your Third-Party Account.
          </li>

          <li>
            We may also collect Personal Information at other points in the
            Services that state that Personal Information is being collected.
          </li>
        </ul>

        <p>
          By creating an account with WYSPP, you affirmatively consent to
          WYSPP’s collection of and use of the above information, including
          transfer of that information to servers located in the United States.
          You can turn off your GPS location on your mobile device at any time,
          but you acknowledge that you cannot stream/upload/interact with
          content on the WYSPP application while your GPS location tracking is
          disabled. You understand WYSPP needs your GPS location tracking data
          to verify the accuracy of your location to post to WYSPP.
        </p>

        <p>
          Please keep in mind that if you directly disclose personally
          identifiable information or personally sensitive data through posting
          or streaming to WYSPP or communications on WYSPP’s public message
          boards, this information may be accessible by others and be collected
          and used by others.
        </p>

        <h1>3.0.0 Information We Collect Automatically.</h1>
        <p>
          When you access and/or use the Services, we automatically collect
          certain information through cookies, web beacons and other
          technologies and store it in our log files. This information includes
          anonymous identifiers, operating system and version, your Internet
          protocol (IP) address, pages you view on our Site, search terms you
          entered to arrive at our Site and other information regarding your
          access to and/or use of the Services. We may combine this information
          with other information that we have collected about you, including,
          where applicable, your username, name, and other Personal Information.
        </p>

        <h1>3.1.0 Location Information.</h1>
        <p>
          The Site may allow access to, or make available opportunities for you
          to view, and/or receive, certain content, products, services,
          information, and other materials based, in whole or in part, upon your
          location (“Location Based Content”). In order to make Location Based
          Content available to you, the Services will determine your location
          using one or more points of data, reference and/or information
          associated with, among other things, the device you use to access and
          use the Services (a “Device”), including, without limitation, GPS,
          beacons, device software/features/services and other points of data,
          reference, and information. If you have set your device to disable
          GPS, Bluetooth, Wi-Fi, or other location determining or assisting
          software, features or services, or do not authorize the Services to
          access your location data, the Services may not be able to determine
          your location and you may not be able to access, view and/or receive
          Location Based Content.
        </p>

        <h1>3.2.0 Address Book.</h1>
        <p>
          We may periodically access and store your contact list and/or address
          book (and the information contained therein) on your Device to, among
          other things, find and keep track of mobile phone numbers of other
          users of the Services, and to allow you and us to send notifications
          (including, without limitation, invitations, and requests) to your
          contacts, as well as other users of the Site, via SMS text, email, and
          other methods. Prior to accessing your contact list or address book,
          we will ask for your consent to do so. If you do not consent to our
          access of such information, you may not be able to use certain
          functionality within the Site.
        </p>

        <h1>3.3.0 Information We Obtain from Third Party Sources. </h1>
        <p>
          From time to time, we receive Personal Information about you from
          third party sources but only where we have received assurances from
          these third parties that they have either received your consent or are
          otherwise legally permitted or required to disclose your Personal
          Information to us. The types of information we may collect from third
          parties include your name and email address, phone number, gender, and
          physical address. We use the information we receive from these third
          parties in order to provide services to those third parties (for
          example, payment and order processing services).
        </p>

        <h1>4.0.0 Third Party Links.</h1>
        <p>
          WYSPP may contain links to other sites. Please be aware that we are
          not responsible for the content or privacy practices of such other
          sites. We encourage our users to be aware when they leave our site and
          to read the privacy statements of any other site that collects
          personally identifiable information.
        </p>

        <h1>5.0.0 How We Use Your Information. </h1>
        <p>
          WYSPP collects and uses your personal information to operate its
          website(s) and applications and to deliver its services. WYSPP DOES
          NOT sell, rent, or lease its customer lists to third parties. WYSPP
          may use your personal information, for example, as follows:
        </p>
        <ul>
          <li>
            To provide the Services to you, to communicate with you about your
            use of the Services, to respond to your inquiries, to fulfill your
            orders, and for other customer service purposes.
          </li>

          <li>
            To tailor the content and information that we may send or display to
            you, to offer location customization, or to otherwise personalize
            your experiences while using the Services.
          </li>

          <li>
            For marketing and promotional purposes. For example, we may send you
            emails about products or information we think may interest you. We
            may also use the information that we learn about you to help
            determine when and how to advertise our services on third-party
            websites, advertising networks and services, or to customize
            advertising to you or to customize your experience on our Site.
          </li>

          <li>
            To verify your eligibility to win a prize in a sweepstakes, contest,
            or giveaway you have entered and to deliver that prize to you.
          </li>

          <li>
            For the prevention and detection of fraud or infringement of our or
            any third party’s rights.
          </li>

          <li>
            To better understand how users access and use our services, both on
            an aggregated and individualized basis, in order to improve our
            services, respond to user preferences, and for other research and
            analytical purposes.
          </li>

          <li>To diagnose service or technical problems.</li>

          <li>To ensure and maintain the security of the services.</li>

          <li>To comply with legal obligations or legal process.</li>

          <li>
            For any other purposes described in this Privacy Policy or disclosed
            at the time you provide us with the Personal Information.
          </li>
        </ul>

        <h1>5.1.0 Use of Cookies. </h1>
        <p>
          The WYSPP website may use “cookies” to help you personalize your
          online experience. A cookie is a text file that is placed on your hard
          disk by a web page server (“Cookies”). Cookies cannot be used to run
          programs or deliver viruses to your computer. Cookies are uniquely
          assigned to you and can only be read by a web server in the domain
          that issued the cookie to you.
        </p>

        <p>
          One of the primary purposes of Cookies is to provide a convenience
          feature to save you time. The purpose of a Cookie is to tell the Web
          server that you have returned to a specific page. For example, if you
          personalize WYSPP pages, or register with WYSPP sites or services, a
          Cookie helps WYSPP to recall your specific information on subsequent
          visits. This simplifies the process of recording your personal
          information, such as billing addresses, shipping addresses, and so on.
          When you return to the same WYSPP website, the information you
          previously provided can be retrieved, so you can easily use the WYSPP
          features that you customized.
        </p>

        <p>
          You have the ability to accept or decline cookies. Most Web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          Cookies, you may not be able to fully experience the interactive
          features of the WYSPP services or websites you visit.
        </p>

        <p>
          By creating an account with WYSPP, you authorize WYSPP to use your
          Cookies for your use of the website.
        </p>

        <h1>5.2.0 Do Not Track Signals.</h1>
        <p>
          WYSPP does not respond to “Do Not Track” signals. WYSPP does not track
          its customers over time and across third party websites to provide
          targeted advertising and therefore does not respond to Do Not Track
          (DNT) signals. However, some third-party sites do keep track of your
          browsing activities when they serve you content, which enables them to
          tailor what they present to you. If you are visiting such third-party
          sites, then you should check your browser settings and the third-party
          site’s privacy policy to ensure that you are protected.
        </p>

        <h1>5.2.1 Tracking User Behavior by WYSPP. </h1>
        <p>
          WYSPP may keep track of the websites and pages our users visit within
          the app or site, in order to determine what WYSPP services are the
          most popular. This data is used to deliver customized content and
          advertising within WYSPP to customers whose behavior indicates that
          they are interested in a particular subject area. By creating an
          account with WYSPP, you authorize WYSPP to track your activity on the
          WYSPP site and application.
        </p>

        <h1>
          5.3.0 Sharing Information with Third Parties and Service Providers.
        </h1>
        <p>
          In certain situations, WYSPP may employ other companies and people to
          perform tasks on our behalf and may share your information with them
          to provide that service. Except as stated in this Privacy Policy or
          unless we inform you otherwise, our service providers do not have any
          right to use the Personal Information we share with them beyond what
          we deem necessary to assist us in performing such tasks. WYSPP may
          share data with trusted partners to help perform statistical analysis,
          send you email or postal mail, or provide customer support. All such
          third parties are prohibited from using your personal information
          except to provide these services to WYSPP, and they are required to
          maintain the confidentiality of your information under this Privacy
          Policy.
        </p>

        <p>
          By creating an account with WYSPP, you affirmatively acknowledge that
          WYSPP may disclose your personal information, without notice, if
          required to do so by law or in the good faith belief that such action
          is necessary to: (a) conform to the edicts of the law or comply with
          legal process served on WYSPP; (b) protect and defend the rights or
          property of WYSPP; and/or (c) act under exigent circumstances to
          protect the personal safety of users of WYSPP, or the public.
        </p>

        <h1>5.3.1 Sharing of Non-Personal Information.</h1>

        <p>
          In certain situations, WYSPP may share, sell, rent, lease, or disclose
          non-personal data, such as Aggregated Information including, but not
          limited to, the total number of times people engaged with a post,
          demographics, the number of people who clicked on a particular link or
          voted on a poll, the topics or events that people are engaging or
          posting about in a particular location, some inferred interests,
          aggregate location data including the number of active users within a
          particular geographic location, or reports to advertisers about how
          many people saw or clicked on their ads. WYSPP does not sell your
          personal data as defined under the California Consumer Privacy Act and
          this Privacy Policy. Aggregate information collected, analyzed, and
          shared by WYSPP DOES NOT disclose any identifiable information or
          personal information of a particular WYSPP user, rather, it is
          collective correlations, trends, and analytics pertaining to WYSPP
          services and users in its entirety. For more information about the
          aggregated data we collect and share with others, please email us at
          policy@wyspp.com. All requests must be clearly labeled “Aggregated
          Information ” in the email subject line and contain a short detailed
          description about the information sought.
        </p>

        <h1>
          6.0.0 California Consumer Privacy Act, General Data Protection
          Regulation.
        </h1>

        <p>
          WYSPP may ask you to disclose personal information or may collect
          personal information about you pursuant to this Privacy Policy. You
          may ask WYSPP to disclose what personal information we have about you
          and what we do with that information, you may request to delete your
          personal information and opt-out of the sale of your information.
          Generally, WYSPP may not discriminate against you for exercising your
          rights under this Privacy Policy. All information that WYSPP collects
          about you is data portable, meaning that WYSPP will store your
          information in a format that can be easily shared and understood by
          you. By creating an account with WYSPP, you agree to provide accurate
          information to WYSPP. Subject to certain exceptions set out below, on
          receipt of a verifiable requests from you asserting your rights under
          this Privacy Policy, we will:
        </p>
        <ul>
          <li>
            Send you all personal information we have in our database about you;
          </li>

          <li>Correct any erroneous information at your request;</li>

          <li>Delete your personal information from our records; and</li>

          <li>
            Direct any service providers to delete your personal information
            from their records.
          </li>
        </ul>

        <h1>6.1.0 Right to Know, Right to Access.</h1>
        <p>
          Registered users may request WYSPP to disclose the information that we
          have collected, used, shared, or sold about you, and why we collected,
          used, shared, or sold that information. All requests must be labeled
          “Request to Know” on the email subject line. Specifically, you may
          request that WYSPP disclose:
        </p>
        <ul>
          <li>Categories of personal information collected;</li>
          <li>Specific pieces of personal information collected;</li>
          <li>
            Categories of sources from which the business collected personal
            information;
          </li>
          <li>Purposes for which the business use the personal information;</li>
          <li>
            Categories of third parties with whom the business shares the
            personal information; and,
          </li>
          <li>
            Categories of information that the business sells of discloses to
            third parties.
          </li>
        </ul>

        <h1>6.2.0 Right to Correct.</h1>

        <p>
          Registered users may request that WYSPP correct personal information
          that we have collected from you. All requests must be labeled “Request
          to Correct Information” on the email subject line. You may correct any
          inaccurate or incomplete personal data that we have collected.
        </p>

        <h1>6.3.0 Right to Delete, Right to Erasure.</h1>

        <p>
          Registered users may request that WYSPP delete personal information
          that we have collected from you. All requests must be labeled “Request
          to Delete” or “Request to Erase” on the email subject line.
        </p>

        <h1>6.4.0 Right to Opt-Out, Right to Restrict Processing.</h1>

        <p>
          Registered users may request that WYSPP stop selling your personal
          information or cease processing and using your data if you believe
          that the information is inaccurate, is being used illegally, or is no
          longer needed for WYSPP to perform its services. All requests must be
          labeled “Request to Opt-Out” or “Request to Restrict Processing” on
          the email subject line. The sale of information means selling,
          renting, releasing, disclosing, disseminating, making available,
          transferring, or otherwise communicating orally, in writing, or by
          electronic or other means, a consumer’s personal information by the
          business to another business or a third party for monetary or other
          valuable consideration. Generally, WYSPP DOES NOT engage in the sale
          of personal information. For purposes of this section, WYSPP does not
          sell personal information.
        </p>

        <h1>6.5.0 Right to Non-Discrimination.</h1>

        <p>
          WYSPP cannot deny goods or services, charge you a different price, or
          provide a different level or quality of goods or services just because
          you exercise your rights under this Privacy Policy. However, if you
          refuse to provide your personal information to WYSPP or request WYSPP
          to delete or stop selling your personal information, and that personal
          information or sale is necessary for WYSPP to provide you with goods
          or services, then WYSPP may not be able to complete your transaction
          or provide its services.
        </p>

        <h1>6.6.0 Right to Object to Requests.</h1>

        <p>
          In certain situations, WYSPP may enforce its right to object to any
          Requests to Delete, Requests to Opt-Out, and Requests to Restrict
          Processing. WYSPP will not be required to delete your information if
          it is necessary for WYSPP to maintain your personal information in
          order to:
        </p>

        <h1>
          6.6.1. Not Required to Delete Under the California Consumer Protection
          Act.
        </h1>

        <ul>
          <li>
            Complete the transaction for which the personal information was
            collected, provide a good or service requested by the consumer, or
            reasonably anticipated within the context of a business’s ongoing
            business relationship with the consumer, or otherwise perform a
            contract between the business and the consumer;
          </li>

          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity; or prosecute those responsible for
            that activity;
          </li>

          <li>
            Debug to identify and repair errors that impair existing intended
            functionality;
          </li>

          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law;
          </li>

          <li>
            Comply with the California Electronic Communications Privacy Act
            pursuant to Chapter 3.6 (commencing with Section 1546) of Title 12
            of Part 2 of the Penal Code;
          </li>

          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when the businesses’
            deletion of the information is likely to render impossible or
            seriously impair the achievement of such research, if the consumer
            has provided informed consent;
          </li>

          <li>
            To enable solely internal uses that are reasonably aligned with the
            expectations of the consumer based on the consumer’s relationship
            with the business;
          </li>

          <li>Comply with a legal obligation; and,</li>

          <li>
            Otherwise use the consumer’s personal information, internally, in a
            lawful manner that is compatible with the context in which the
            consumer provided the information.
          </li>
        </ul>

        <h1>
          6.6.2 Right to Object to Processing Under General Data Protection
          Regulation.
        </h1>

        <ul>
          <li>Exercise the right of freedom of expression and information;</li>

          <li>
            To compliance with a legal obligation within the European Union,
            which requires processing by Union or Member State law to which the
            controller is subject or for the performance of a task carried out
            in the public interest or in the exercise of official authority
            vested in the controller;
          </li>

          <li>
            For reasons of public interest in the area of public health in
            accordance with points (h) and (i) of Article 9(2) as well as
            Article 9(3) of the General Date Protection Regulation;
          </li>

          <li>
            For archiving purposes in the public interest, scientific or
            historical research purposes or statistical purposes in accordance
            with Article 89(1) of the General Date Protection Regulation in so
            far as the right referred to in paragraph 1 is likely to render
            impossible or seriously impair the achievement of the objectives of
            that processing; and,
          </li>

          <li>For the establishment, exercise or defense of legal claims.</li>
        </ul>

        <h1>6.7.0 Requests.</h1>
        <p>
          All requests under this section must be emailed to policy@wyspp.com.
          All requests must provide a description of the content or information
          in you want removed and information reasonably sufficient to permit us
          to locate the material and, so that we can process your request and
          contact you if we have questions, include your registered username,
          name, street address, city, state, zip code and email address (your
          street address is optional if you wish to receive a response to your
          request via email), include the name and URL (if applicable) of the
          website, application or other interactive service and indicate your
          preference on how our response to your request should be sent (email
          or postal mail). We shall not accept requests via postal mail,
          telephone, or facsimile. We are not responsible for notices that are
          not labeled or sent properly and may not be able to respond if you do
          not provide complete information. Please note that any requests for
          removal do not ensure complete or comprehensive removal of the Content
          or information from the Services. For example, content that you have
          posted may be republished or reposted by another User or third party.
        </p>

        <h1>7.0.0 Children’s Online Privacy Protection Act.</h1>
        <p>
          The rules of the Children’s Online Privacy Protection Act are directed
          towards children under the age of 13 years old. WYSPP does not
          knowingly collect personally identifiable information from children
          under the age of thirteen. If you are under the age of thirteen, you
          must ask your parent or guardian for permission to use this WYSPP
          services. Upon parental or guardian consent to use WYSPP services,
          WYSPP may collect personal information about its users who are
          children under the age of 13. We understand and respect the private
          information of our young users. Therefore, we maintain the
          confidentiality, security, and integrity of information we collect
          from children, including taking reasonable steps to release such
          information to parties capable of maintain its confidentiality and
          security. If you are the parent or legal guardian of a child under the
          age of 13 who is intending to use our services then by agreeing to the
          terms of this Agreement, you expressly consent to the use of your
          child’s personal information by WYSPP for the purpose necessary to
          provide services under this Agreement. For more information regarding
          the WYSPP data security program and safeguards to protect your child’s
          information, please read Section 10.0.
        </p>

        <h1>7.1.0 Request Under Children’s Online Privacy Protection Act.</h1>

        <p>
          If you wish to prevent further use or online collection of a child’s
          personal information or delete your child’s personal information or
          account, please email policy@wyspp.com. All requests must be clearly
          labeled “Request Under Children’s Online Privacy Protection Act”. All
          requests must provide a description of the content or information in
          your User Posting that you want removed and information reasonably
          sufficient to permit us to locate the material and, so that we can
          process your request and contact you if we have questions, include
          your registered username, name, street address, city, state, zip code
          and email address (your street address is optional if you wish to
          receive a response to your request via email), include the name and
          URL (if applicable) of the website, application or other interactive
          service and indicate your preference on how our response to your
          request should be sent (email or postal mail). We shall not accept
          requests via postal mail, telephone, or facsimile. We are not
          responsible for notices that are not labeled or sent properly and may
          not be able to respond if you do not provide complete information.
        </p>

        <h1>8.0.0 Stop Hacks and Improve Electronic Security Act.</h1>

        <p>
          In compliance with the State of New York’s Stop Hacks and Improve
          Electronic Security Act, WYSPP has developed, implemented, and
          maintains reasonable safeguards to protect the security,
          confidentiality, and integrity of the private information of its
          users.
        </p>

        <h1>8.1.0 Data Security Program.</h1>

        <p>
          WYSPP has implemented a data security program to maintain reasonable
          safeguards to ensure the protection of its user’s private information.
          Reasonable safeguards that WYSPP takes to ensure the protection of
          personal information includes, but is not limited to:
        </p>
        <ul>
          <li>
            Designating an employee or employees to coordinate the data security
            program;
          </li>

          <li>
            Training and managing employees in the security program practices
            and procedures;
          </li>

          <li>
            Assessing internal and external risks and implementing controls to
            reduce those risks;
          </li>

          <li>
            Vetting service providers and binding them contractually to
            safeguard private information; and,
          </li>

          <li>
            Securely destroying private information within a reasonable amount
            of time after it is no longer needed for business purposes.
          </li>
        </ul>

        <h1>8.1.1 Security of Your Personal Information.</h1>

        <p>
          WYSPP secures your personal information from unauthorized access, use,
          or disclosure. WYSPP uses the following methods for this purpose:
          <ul>
            <li>SSL Protocol</li>
          </ul>
        </p>

        <p>
          When personal information (such as a credit card number) is
          transmitted to other websites, it is protected through the use of
          encryption, such as the Secure Sockets Layer (SSL) protocol.
        </p>

        <p>
          We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information.
          Unfortunately, no data transmission over the Internet or any wireless
          network can be guaranteed to be 100% secure. As a result, while we
          strive to protect your personal information, you acknowledge that: (a)
          there are security and privacy limitations inherent to the Internet
          which are beyond our control; and (b) security, integrity, and privacy
          of any and all information and data exchanged between you and us
          through this Site cannot be guaranteed.
        </p>

        <h1>9.0.0 Email Communications.</h1>

        <p>
          From time to time, WYSPP may contact you via email for the purpose of
          providing announcements, promotional offers, alerts, confirmations,
          surveys, and/or other general communication.
        </p>
        <p>
          If you would like to stop receiving marketing or promotional
          communications via email from WYSPP, you may opt out of such
          communications by going into your settings account and clicking on
          UNSUBSCRIBE at the bottom of the webpage.
        </p>

        <h1>10.0.0 Changes to this Privacy Policy.</h1>

        <p>
          WYSPP reserves the right to change this Privacy Policy from time to
          time. We will notify you about significant changes in the way we treat
          personal information by sending a notice to the primary email address
          specified in your account, by placing a prominent notice on our site,
          and/or by updating any privacy information on this page. Your
          continued use of the Site and/or Services available through this Site
          after such modifications will constitute your: (a) acknowledgment of
          the modified Privacy Policy; and (b) agreement to abide and be bound
          by that Policy.
        </p>

        <h1>11.0.0 Contact Information.</h1>

        <p>
          WYSPP welcomes your questions or comments regarding this Statement of
          Privacy. If you believe that WYSPP has not adhered to this Statement,
          please contact WYSPP at: policy@wyspp.com
        </p>
        <h1>Effective as of September 1, 2021</h1>
      </div>
    </div>
  );
};

export default PrivacyContentComponent;
