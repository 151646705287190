import React from "react";
import ConfirmationIcon from "../../../assets/images/confirmation-icon.svg"; // Import using relative path

const Confirmation = () => {
  return (
    <div
      className="container  d-flex align-items-center  flex-column justify-content-center px-5"
      style={{ minHeight: "95vh" }}
    >
      <div className="auth-form-container pt-5 px-5 pb-3 col-md-12 d-flex flex-column px-5  ">
        <div className="w-100 d-flex justify-content-center">
          <img
            src={ConfirmationIcon}
            className="auth-confirmation-icon"
            alt="logo"
          />
        </div>

        <h1 className="auth-confirmation-title pb-4">
          Thank You For Contacting us!
        </h1>
        <p className="confirmation-text">
          We will get in touch with you shortly.
        </p>

        {/* <h3 className="confirmation-text" style={{ textAlign: "left" }}>
          Need to contact us?{" "}
        </h3> */}
        <p className="confirmation-text">
          Send us a note and we will get back to you as soon as possible.
          Download issues should be addressed at the app store where WYSPP was
          downloaded from.
        </p>
      </div>
    </div>
  );
};

export default Confirmation;
