
import { userModel } from "./user";

export const commentModelIncoming = (comment) => ({
  id: comment.id,
  author: userModel(comment.owner),
  createdAt: comment.created_at,
  description: comment.message,
  postId: comment.post !== undefined ? comment.post.id : 0,
});
