import { useSelector } from "react-redux";
import DarkLogo from "../../../assets/images/title-icon.svg"; // Import using relative path
import LightLogo from "../../../assets/images/title-icon-light.svg"; // Import using relative path

function LogoIcon(props) {
  const currentTheme = useSelector((state) => state.auth.theme);
  return (
    <img
      style={{
        marginRight: "10px",
        width: "50px",
      }}
      src={currentTheme === "light" ? LightLogo : DarkLogo}
      className="auth-logo img-fluid"
      alt="logo"
    />
  );
}

export default LogoIcon;
