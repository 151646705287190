import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../services/Auth";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? {
      isLoggedIn: true,
      user,
      error: "",
      isLoading: false,
      theme: localStorage.getItem("theme") || "dark",
    }
  : {
      isLoggedIn: false,
      user: null,
      error: "",
      isLoading: false,
      theme: localStorage.getItem("theme") || "dark",
    };

export const login = createAsyncThunk(
  "auth/login",
  async ({ identity, password }) => {
    try {
      const data = await AuthService.login(identity, password);

      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (data) => {
    try {
      var res;

      if (data.email !== undefined) {
        res = await AuthService.forgetPasswordByEmail(data.email);
      } else {
        res = await AuthService.forgetPasswordByMobile(data);
      }

      return res;
    } catch (error) {
      return { error };
    }
  }
);

export const forgetPasswordConfirmation = createAsyncThunk(
  "auth/forgetPasswordConfirmation",
  async (email, code) => {
    try {
      const data = await AuthService.emailVerification(email, code);

      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const emailVerification = createAsyncThunk(
  "auth/emailVerification",
  async (email, code) => {
    try {
      const data = await AuthService.emailVerification(email.email, email.code);

      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const forgetPasswordEmailConfirmation = createAsyncThunk(
  "auth/forgetPasswordEmailConfirmation",
  async (email, code) => {
    try {
      const data = await AuthService.forgetPasswordEmailConfirmation(
        email.email,
        email.code
      );

      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const phoneVerification = createAsyncThunk(
  "auth/phoneVerification",
  async (phone, code, sid) => {
    try {
      if (sid !== undefined) {
        return await AuthService.resetPasswordPhoneVerification(phone, code);
      } else {
        return await AuthService.phoneVerification(phone, code);
      }
    } catch (error) {
      return { error };
    }
  }
);

export const sendEmailCode = createAsyncThunk(
  "auth/sendEmailVerification",
  async (email) => {
    try {
      const data = await AuthService.sendEmailCodeVerification(email);

      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const sendPhoneCode = createAsyncThunk(
  "auth/sendPhoneCode",
  async (phone) => {
    try {
      const data = await AuthService.sendPhoneCodeVerification(phone);
      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const setUser = (state, action) => {
  state.user = action.payload;
};

export const signup = createAsyncThunk("auth/signup", async (formData) => {
  try {
    const data = await AuthService.signup(formData);
    return data;
  } catch (error) {}
});

export const accountcheck = createAsyncThunk(
  "auth/accountcheck",
  async (formData) => {
    try {
      const data = await AuthService.accountcheck(formData);
      if (data.msg === "not_confirmed_account")
        localStorage.setItem("user", JSON.stringify(formData));

      return data;
    } catch (error) {}
  }
);
export const social = createAsyncThunk("auth/social", async (socialType) => {
  try {
    const data = await AuthService.verifySocial(
      socialType.socialType,
      socialType.secretKey
    );

    return data;
  } catch (error) {}
});

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (password, code) => {
    try {
      const data = await AuthService.resetPassword(password, code);
      return data;
    } catch (error) {}
  }
);

export const getRefreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (refreshToken) => {
    try {
      const data = await AuthService.refreshToken(refreshToken);
      return data;
    } catch (error) {}
  }
);

export const follow = createAsyncThunk("auth/follow", async ({ username }) => {
  try {
    const data = await AuthService.follow(username);

    return data;
  } catch (error) {
    return { error };
  }
});

export const unfollow = createAsyncThunk(
  "auth/unfollow",
  async ({ username }) => {
    try {
      const data = await AuthService.unfollow(username);

      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const hidePost = createAsyncThunk("auth/post", async (slug) => {
  try {
    const data = await AuthService.hidePost(slug);

    return data;
  } catch (error) {
    return { error };
  }
});

export const bookmarkPost = createAsyncThunk("auth/post", async (slug) => {
  try {
    const data = await AuthService.bookmarkPost(slug);

    return data;
  } catch (error) {
    return { error };
  }
});

export const removeBookmark = createAsyncThunk("auth/post", async (slug) => {
  try {
    const data = await AuthService.removeBookmarkPost(slug);

    return data;
  } catch (error) {
    return { error };
  }
});

export const getPeopleToFollow = createAsyncThunk(
  "auth/getPeopleToFollow",
  async ({ limit }) => {
    try {
      const data = await AuthService.getPeopleToFollow({ limit });

      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const getMessagesList = createAsyncThunk(
  "auth/getMessagesList",
  async ({ limit }) => {
    try {
      const data = await AuthService.getMessagesList({ limit });

      return data;
    } catch (error) {
      return { error };
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      console.log(state);
      state.theme = state.theme === "light" ? "dark" : "light";
    },
  },

  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },

    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [signup.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.error = action.payload.error;
    },
    [social.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.error = null;
    },
    [accountcheck.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = action.payload.user;

      state.error = action.payload.error;
    },
    [signup.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.error = action.payload.error;
    },

    [forgetPassword.fulfilled]: (state, action) => {
      state.user = { email: action.payload.email };
    },

    [getRefreshToken.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export const { toggleDarkMode } = authSlice.actions;

export default reducer;
