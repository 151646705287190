import React, { useEffect,useState,useRef} from "react";
import { useDispatch,useSelector } from "react-redux";
import { toggleDarkMode } from "../../../slices/auth";


function ModeToggle(props) {
    const initTheme = useSelector((state) => state.auth.theme);
    let [theme, setTheme] = useState(initTheme);
    let currentTheme = useRef(initTheme);
    const dispatch = useDispatch();
    
    return (
          <div
           className={[
            'mb-2 d-flex flex-row align-self-center justify-content-end',
          ].join(' ')}>
            <span
              className="theme-label me-2"
            >
              Light
            </span>
            <input
              className="react-switch-checkbox"
              id={`react-switch-new`}
              checked={theme === "dark"}
              type="checkbox"
              onChange={() => {  
                setTheme(theme === "light" ? "dark" : "light");
                currentTheme.current =
                  currentTheme.current === "light" ? "dark" : "light";
              
                localStorage.setItem("theme", currentTheme.current);
                
                if (currentTheme.current === "light") {
                  var stylePath = "/assets/css/lightmode_v1.css";
                  var head = document.head;
                  var link = document.createElement("link");
                  link.type = "text/css";
                  link.rel = "stylesheet";
                  link.id = "light-styles";
                  link.href = stylePath;
                  head.appendChild(link);
                } else {
                  const styleTag = document.getElementById("light-styles");
                  console.log("style element: " + styleTag);
                  if (!styleTag) return;
                  document.getElementsByTagName("head")[0].removeChild(styleTag);
                }
                dispatch(toggleDarkMode());
              }}
            />
            <label
              className="react-switch-label"
              htmlFor={`react-switch-new`}
              style={{ backgroundColor: theme === "dark" ? "white" : "#090B22" }}
            >
              <span
                className={`react-switch-button`}
                style={{
                  backgroundColor: theme === "light" ? "white" : "#090B22",
                }}
              />
            </label>
  
            <span
              className="theme-label ms-2"
            >
              Dark
            </span>
          </div>
        
    );
}

export default ModeToggle;
