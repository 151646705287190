import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./config/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
const container = document.getElementById("root");
const root = createRoot(container);

var stylePath = 'assets/css/lightmode_v1.css';
var head = document.head;
var link = document.createElement("link");
link.type = "text/css";
link.rel = "stylesheet";
link.href = stylePath;
link.id = "light-styles";

var theme = localStorage.getItem("theme");
if (theme === "light") {
  head.appendChild(link);
}

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
