import React, { useState, useRef } from "react";

import HomeIphone from "../../../../../assets/images/iPhone-13-Pro-Front.png";
import "../../../../../assets/css/home/Landingpage.css";
import Dash from "../../../../../assets/images/white-dash.svg";
import GooglePlayButton from "../../../../../assets/images/google-play.png";
import DarkGooglePlayButton from "../../../../../assets/images/dark_google_play.png";
import AppStoreButton from "../../../../../assets/images/app-store.png";
import DarkAppStoreButton from "../../../../../assets/images/darkapple.png";
import { useSelector } from "react-redux";

const Intro = () => {
  const [loading, setLoading] = useState(true);
  const urls = [Dash];
  const counter = useRef(0);

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= urls.length) {
      setLoading(false);
    }
  };
  const currentTheme = useSelector((state) => state.auth.theme);
  return (
    <section className=" smallScreenBgSize pt-5 ">
      <div className="container pt-4 pb-5">
        <div className="row pt-5">
          <div className="col-md-8 col-sm-12  pt-4  Intro-big-text">
            {/* <div className=" d-flex justify-content-start align-items-center">
              <span className="dash"></span>
              <span className="small-text">A truth-Centered toolbox</span>
            </div> */}
            <div>
              <div className="very-big-text">Get Informed, Stay Involved</div>
            </div>
            <div>
              <p className="big-text intro-2nd-text">
                WYSPP's toolbox of technologies enables users to come together
                and collaborate, encouraging the sharing of information that can
                be used to create a space for meaningful participation globally
                in decision-making. This open platform facilitates higher levels
                of trust and accountability as it encourages a more transparent
                dialogue around the world.
              </p>
            </div>

            <div className="  d-md-block justify-content-start center-on-sm bottom-space flex-row intro-button-div">
              <img
                role="button"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/wyspp/id1541291526",
                    "_blank"
                  )
                }
                src={
                  currentTheme === "light" ? DarkAppStoreButton : AppStoreButton
                }
                className="download-app-btn img-fluid"
                alt="logo"
              />

              <img
                role="button"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.wyspp.appMobile&pli=1",
                    "_blank"
                  )
                }
                src={
                  currentTheme === "light"
                    ? DarkGooglePlayButton
                    : GooglePlayButton
                }
                className="download-app-btn img-fluid me-0"
                alt="logo"
              />
            </div>
          </div>

          <div className="d-md-6 col-sm-12 justify-content-end align-items-end  center-on-sm mt-md-1">
            {/* <div
              style={{
                display: loading ? "block" : "none",
                marginRight: "0%",
                backgroundColor: "orange",
              }}>
               <div className="spinner-border mx-auto" role="status"></div> 
            </div>*/}
            <div
              style={{
                display: loading ? "none" : "block",
              }}
              className="justify-content-end
                align-items-end
                d-flex
                center-on-sm  
            ">
              <img
                key={HomeIphone}
                src={HomeIphone}
                onLoad={imageLoaded}
                className="fluid img-fluid intro-img"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
