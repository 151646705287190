import React, { useState, useRef } from "react";

import AboutPhone from "../../../../../assets/images/AboutIPhone.png"; // Import using relative path
import WatchPhone from "../../../../../assets/images/Watch-Mob2.png";
// Import using relative path
import WatchIconDark from "../../../../../assets/images/WatchIconDark.png"; //darktheme
import WatchIconLight from "../../../../../assets/images/WatchIconLight.png"; //lighttheme
import ConnectPhone from "../../../../../assets/images/Connect-Mob2.png"; // Import using relative path
import ConnectIconDark from "../../../../../assets/images/ConnectIconDark.png"; //darktheme
import ConnectIconLight from "../../../../../assets/images/ConnectIconLight.png";
import MissingPhone from "../../../../../assets/images/Missing-Mob2.png"; // Import using relative
import MissingIconDark from "../../../../../assets/images/MissingIconDark.png";
import MissingIconLight from "../../../../../assets/images/MissingIconLight.png";
import RatingPhone from "../../../../../assets/images/Ratings-Mob2.png"; // Import using relative path
import RatingIconDark from "../../../../../assets/images/RatingIconDark.png";
import RatingIconLight from "../../../../../assets/images/RatingIconLight.png";
import InventoryPhone from "../../../../../assets/images/Inventory -Mob2.png"; // Import using relative path
import InventoryIconDark from "../../../../../assets/images/InventoryIconDark.png";
import InventoryIconLight from "../../../../../assets/images/InventoryIconLight.png";
import WatchLightPhone from "../../../../../assets/images/watch-slider-light.png";
import ConnectLightPhone from "../../../../../assets/images/connect-slider-light.png";
import missingLightPhone from "../../../../../assets/images/missing-slider-light.png";
import RatingLightPhone from "../../../../../assets/images/rating-slider-light.png";
import InventoryLightPhone from "../../../../../assets/images/inventory-slider-light.png";

import LogoIcon from "../../../../shared/components/LogoIcon"; // Import using relative path
import Slider from "react-slick";
import { useSelector } from "react-redux";
import "../../../../../assets/css/home/Landingpage.css";

const Features = () => {
  const [loading, setLoading] = useState(true);
  const urls = [AboutPhone];
  const counter = useRef(0);

  const currentTheme = useSelector((state) => state.auth.theme);

  const slidesContent = [
    {
      icon: currentTheme === "light" ? WatchIconLight : WatchIconDark,
      title: "Watch",
      text: "You can report events in your local area and view posts globally as a user of Watch. You can also interact with posts within a certain radius by Shouting or Shushing. Although there is no comment feature, you have the option to Spin to Connect to posts for conversation.",
      image: WatchPhone,
      lightMoodImage: WatchLightPhone,
    },
    {
      icon: currentTheme === "light" ? ConnectIconLight : ConnectIconDark,
      title: "Connect",
      text: "You can communicate freely, discuss diverse topics, and share media worldwide using Connect. Our system thoroughly analyzes and translates submitted media to promote accuracy and genuine dialogue. Additionally, you have the ability to like, comment on, and repost posts as a user.",
      image: ConnectPhone,
      lightMoodImage: ConnectLightPhone,
    },
    {
      icon: currentTheme === "light" ? MissingIconLight : MissingIconDark,
      title: "Missing",
      text: 'We provide a comprehensive "lost and found" service for WYSPP users, connecting people within a 50-mile radius for items and pets, and throughout the nation for persons. You can post about lost items, pets, or persons, and even offer a reward for their safe return.',
      image: MissingPhone,
      lightMoodImage: missingLightPhone,
    },
    {
      icon: currentTheme === "light" ? RatingIconLight : RatingIconDark,
      title: "Rating",
      text: "As a user, you have the ability to rate businesses you visit by assigning them a rating from 1 to 5 stars. Additionally, you can enhance your review by adding photos and videos, as well as flagging any inaccurate information you come across. If a business hasn't been rated yet, you can create a new entry for it.",
      image: RatingPhone,
      lightMoodImage: RatingLightPhone,
    },
    {
      icon: currentTheme === "light" ? InventoryIconLight : InventoryIconDark,
      title: "Inventory",
      text: "With Inventory, you can receive real-time updates on product availability using reliable crowd-sourced local data. You have the capability to look up, track, and update the inventory of items in any store. Furthermore, you can even contribute by adding new items along with their location and availability details.",
      image: InventoryPhone,
      lightMoodImage: InventoryLightPhone,
    },
  ];
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= urls.length) {
      setLoading(false);
    }
  };

  var settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
  };
  return (
    <div className="container mt-5  pt-5">
      <div className=" d-flex justify-content-start align-items-center ">
        <LogoIcon></LogoIcon>
        <span className="title">Features</span>
      </div>
      <Slider {...settings}>
        {slidesContent.map((object, i) => (
          <div
            key={i}
            className=" flex-lg-row flex-md-column flex-sm-column flex-xs-column d-flex flex-wrap">
            <div className="col-lg-5 col-sm-12  col-xs-12 col-md-12  center-on-sm">
              <div className="mt-5">
                <div style={{ display: loading ? "block" : "none" }}>
                  <div className="spinner-border mx-auto" role="status"></div>
                </div>
                <div
                  className="justify-content-end 
                             align-items-center
                            d-flex
                            center-on-sm 
            "
                  style={{ display: loading ? "none" : "block" }}>
                  <img
                    src={
                      currentTheme === "light"
                        ? object.lightMoodImage
                        : object.image
                    }
                    onLoad={imageLoaded}
                    className="imag-fluid about-img mx-auto"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 feature-text">
              <div>
                <img
                  src={object.icon}
                  alt="iconimg"
                  className="feature-icon-img"
                />
                <h3 className="italic-title"> {object.title}</h3>
              </div>

              <div className="mt-4">
                <p className="paragraph slider-paragraph-width">
                  {object.text}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Features;
