import { axiosInstance } from "../config/network";

const getbusiness = ({
  offset,
  limit,
  category,
  name,
  rating,
  priceLevel,
  sort,
  latitude,
  longitude,
}) => {

axiosInstance.interceptors.request.use((config) => {
  config.headers["User-Location"] = `${latitude},${longitude}`;
  return config;
});

return axiosInstance({
  method: "GET",
  params: {
    limit: limit,
    offset: offset,
    category: category,
    name: name,
    rating: rating,
    price_level: priceLevel,
    sort: sort,
    location: `${latitude},${longitude}`,
  },
  // headers: {

  //   "User-Location": `${latitude},${longitude}`,
  // },
  url: "business-review/feed/",
})
  .then((res) => {
    if (res.status === 200 || res.status === 201) {
      return {
        status: true,
        business: res.data.data.results,
        count: res.data.data.count,
      };
    } else {
      return { status: false, error: res.data.data.error };
    }
  })
  .catch((err) => {
    return {
      status: false,
      error: err.response.data.data.error,
    };
  });
};



const getCategories = () => {
  return axiosInstance({
    method: "GET",

    url: "/business-review/categories/",
  }).then((res) => {
    if (res.status === 200 || res.status === 201) {
      return {
        status: true,
        categories: res.data.data,
      };
    } else {
      return { status: false, error: res.data.data.error };
    }
  });
};
const businessService = {
  getbusiness,
  getCategories,
};

export default businessService;
