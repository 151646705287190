import * as React from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import "../../../../../assets/css/home/Landingpage.css";
import LogoIcon from "../../../../shared/components/LogoIcon"; // Import using relative path

export default function FAQ() {
  let navigate = useNavigate();
  var accordionArray = [
    {
      id: 0,
      title: "What is WYSPP?",
      details:
        "Wyspp is the go-to platform for staying informed about the world around you. Using verified crowd-sourcing, it provides a wealth of features including local events, global news, hyper-localized lost and found, business ratings, and stock inventory updates. Get informed and connect today with Wyspp's social news toolbox.",
    },
    {
      id: 1,
      title: "What is Watch?",
      details:
        'Users of Watch can report events they observe in their local area that they believe their community should know about. Posts can be viewed globally if their location is followed, but only users within a certain radius can interact with the post by either Shouting (promoting it) or Shushing (suppressing it) depending on its validity. There are no comments on Watch posts, but users can "Spin to Connect" any post to have an open conversation.',
    },
    {
      id: 2,
      title: "What is Connect?",
      details:
        "Connect offers users the ability to communicate without restrictions, no matter where they are in the world. It encourages dialogue about diverse topics and provides insight into everyday events and issues. Upon submission, media posted will be analyzed and translated by our system so as to ensure accuracy and promote honest discussion. Users have the opportunity to like, comment on, and repost posts as well.        ",
    },
    {
      id: 3,
      title: "What is Missing?",
      details:
        'This feature serves as a comprehensive "lost and found"service, connecting WYSPP users within a 50-mile radius for items and pets, and throughout the nation for persons. Individuals can post about an item, pet, or person lost, and can even offer a reward for their return.        ',
    },
    // {
    //   id: 4,
    //   title: "Is my mobile device supported?",
    //   details: "The WYSPP mobile app is supported on the following platforms",
    // },
  ];
  return (
    <div className="container mt-5 FAQ-container">
      <div className=" d-flex justify-content-start align-items-center">
        <LogoIcon></LogoIcon>
        <span className="title">FAQs</span>
      </div>

      <p className="pt-2 pb-2">
        Answers to the most common questions we get about the WYSPP app:
      </p>
      <Accordion defaultActiveKey="0">
        {accordionArray.map((d) => (
          <Accordion.Item key={d.id} id="Faq-According-item" eventKey={d.id}>
            <Accordion.Header id="Faq-According-Header">
              {d.title}
            </Accordion.Header>
            <Accordion.Body id="Faq-According-body">
              {d.details}
              {d.id === 4 && (
                <ul>
                  <li>iOS 11.0 or later (iPhone, iPod touch, and/or iPad)</li>
                  <li>and Android 5.x.x or later</li>
                </ul>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <div className="d-flex flex-row-reverse bd-highlight mt-2">
        <p
          onClick={() => navigate("/faqs")}
          className="text-end text-decoration-underline see-all">
          Show All FAQs
        </p>
      </div>
    </div>
  );
}
