import React from "react";
import ErrorImage from "../../../assets/images/404-page-not-found-illustration-512x249-ju1c9yxg.png";

const ErrorPage = () => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center row">
        <div className=" col-md-6">
          <img src={ErrorImage} alt="" className="img-fluid" />
        </div>
        <div className=" col-md-6 mt-5">
          <p className="fs-3">
            <span className="text-danger">Opps!</span> Page not found.
          </p>
          <p className="lead">The page you’re looking for doesn’t exist.</p>
          <a href="\" className="btn btn-primary">
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
