//export const baseURL = "http://127.0.0.1:8000/api/v1";
export const baseURL = "http://35.239.237.13/api/v1/";
export const requestTimeout = 10000; // --> 10 seconds
export const googleLoginClientId =
  "349706785268-lg2cmrln8io0o6b5ggiuc5t01euq9gmm.apps.googleusercontent.com";
export const facebookAppId = "318521415986909";
export const twitterApiKey = "ro22H9VRHxviwJ6lWHwvfSRjw";
export const twitterSecretKey =
  "aRep5udmS2miOtP7PRSFvvK00i0u6dL8glDUolYV8nwgcSQlyz";

export const postsOffset = 15;


  export const gcpApiKey = "AIzaSyCwevIoBQwL9xjzBS1y291ngBfjc1EGBBg";
