import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import ReactInputVerificationCode from "react-input-verification-code";
import {
  emailVerification,
  sendEmailCode,
  forgetPasswordEmailConfirmation,
} from "../../../slices/auth";
import lifecycle from "react-pure-lifecycle";

import Form from "react-bootstrap/Form";

const methods = {
  componentDidMount(props) {},
};

const EmailVerificationComponent = () => {
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("0");
  const { state } = useLocation();
  const currentUser = useSelector((state) => state.auth.user);
  const { email } = currentUser;

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onErrors = (errors) => console.error(errors);

  const onFormSubmit = (formValue) => {
    if (state == null) {
      dispatch(forgetPasswordEmailConfirmation({ email: email, code: code }))
        .unwrap()
        .then((value) => {
          if (value.status !== true) {
            setError("resend", { type: "custom", message: value.error });
          } else {
            navigate("/resetpassword", { state: { code: code } });
          }
        })
        .catch(() => {});
    } else
      dispatch(emailVerification({ email: email, code: code }))
        .unwrap()
        .then((value) => {
          if (value.status !== true) {
            setError("resend", { type: "custom", message: value.error });
          } else {
            navigate("/feeds/all");
          }
        })
        .catch(() => {});
  };

  const onResendCode = () => {
    clearErrors("resend");

    dispatch(sendEmailCode(email))
      .unwrap()
      .then((value) => {
        if (value.status !== true) {
          setError("resend", { type: "custom", message: value.error });
        } else {
          setShow(true);
        }
      })
      .catch(() => {});
  };

  return (
    <div
      className="container  d-flex align-items-md-center  flex-column justify-content-center mx-md-5"
      style={{ minHeight: "9s5vh" }}
    >
      <div className="auth-form-container pt-5 px-md-5 pb-3 col-md-12 d-flex flex-column mx-md-5">
        <h1 className="verification-title">Verify Your Email</h1>

        <Form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
          <div className="pb-3 mx-3  my-4">
            <ReactInputVerificationCode
              value="      "
              onChange={(v) => {
                setCode(v.trim());
              }}
              length={6}
            />
          </div>

          <p className="note pb-5 px-1 pt-2">
            We’ve sent an OTP code to
            <span className="linkspan"> {email} </span> Enter the 6-digit-code
            to verify your email.
          </p>

          <p className="note" onClick={() => onResendCode()}>
            <span className="linkspan">Click here</span> to resend a new code.
          </p>
          {errors?.resend && (
            <div className="text-danger  py-1 px-1 mx-3" style={{}}>
              {errors.resend.message}
            </div>
          )}
          <div>
            <Button
              type="submit"
              className={"auth-submit-button mt-3  py-2 mx-3 mb-4"}
              disabled={code.replace(/\./g, "").length !== 6}
              onClick={() => clearErrors("resend")}
            >
              Verify
            </Button>
          </div>
        </Form>

        {show && (
          <div className="App">
            <div className="p-4 text-success">Code sent successfully</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default lifecycle(methods)(EmailVerificationComponent);
