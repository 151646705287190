import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import uploadService from "../services/UploadFile";
import { async } from "q";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  status: false,
  postsId: '',
  error: "",
  loading: true,
  url: "",
};


export const uploadFile = createAsyncThunk(
  "uploadfile",
  async ({ file, postId }) => {
    const url = await uploadService.sendFile({ file });
    return { id: postId, url: url };
  }
);



const uploadSlice = createSlice({
  name: "upload",
  initialState,
  extraReducers: {
    [uploadFile.fulfilled]: (state, action) => {
      state.status = true;
      state.postsId = action.payload.id;
      state.error = null;
      state.loading = false;
      state.url = action.payload.url;
    },

    [uploadFile.rejected]: (state, action) => {
      state.status = true;
      state.postsId = null;
      state.error = action.payload.url;
    },
  },
});

const { reducer } = uploadSlice;
export default reducer;
