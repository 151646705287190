export const RegistrationStatusTypes = {
  NotConfirmed: "not_confirmed_account",
  UserExist: "username_already_exist",
  EmailExist: "email_already_exist",
  PhoneExist: "phone_already_exist",
  UserAndEmailExist: "username_and_email_already_exist",
  UsernameAndPhoneExist: "username_and_phone_already_exist",
  UsernameAndEmailNotExist: "username_and_email_not_exist",
  UsernameAndPhoneNotExist: "username_and_phone_not_exist",
};

export const socialTypes = {
  facebook: "facebook",
  twitter: "twitter",
  google: "google-oauth2",
};
export const CheckAccountStatusErrors = {
  username_already_exist: "User name already exist",
  email_already_exist: "Email already exist",
  username_and_email_already_exist: "Username and email already exist",
};


export const userModel = (user) => ({
  id: user.id,
  name: user.name,
  username: user.username,
  email: user.email,
  avatar: user.avatar,
});
