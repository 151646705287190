import { useForm } from "react-hook-form";
import { emailSubscribe } from "../../../../../slices/general";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import { ReactComponent as LeftSection } from "../../../../../assets/images/mail-section/left_section_dark.svg";
import { ReactComponent as RightSection } from "../../../../../assets/images/mail-section/right_section_dark.svg";
import { ReactComponent as LightLeftSection } from "../../../../../assets/images/mail-section/left_section_light.svg";
import { ReactComponent as LightRightSection } from "../../../../../assets/images/mail-section/right_section_light.svg";

import { ReactComponent as TopSection } from "../../../../../assets/images/mail-section/top_section_dark.svg";
import { ReactComponent as BottomSection } from "../../../../../assets/images/mail-section/bottom_section_dark.svg";
import { ReactComponent as LightTopSection } from "../../../../../assets/images/mail-section/top_section_light.svg";
import { ReactComponent as LightBottomSection } from "../../../../../assets/images/mail-section/bottom_section_light.svg";
import "../../../../../assets/css/home/Landingpage.css";
import { useState } from "react";
import ReactLoading from "react-loading";

const MailSubscription = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {
      errors,
      // isDirty, isValid
    },
  } = useForm();

  const dispatch = useDispatch();
  const [isSent, setIsSent] = useState(false);
  const onErrors = (errors) => console.error(errors);
  const [isLoading, setLoading] = useState();
  const currentTheme = useSelector((state) => state.auth.theme);

  const onFormSubmit = (formValue) => {
    setLoading(true);

    dispatch(emailSubscribe(formValue))
      .unwrap()
      .then((value) => {
        setLoading(false);
        if (value.status !== true) {
          setError("email", { type: "custom", message: value.error });
        } else {
          setIsSent(true);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="container mail-bg">
      <div className=" d-flex justify-content-center ">
        <div className="column column-pt">
          <h1 className="mail-title">Join Our Mailing List</h1>

          <div className="mail-form-container mt-3 mb-1">
            <h1 className="mail-subtitle">Subscribe to our mailing List</h1>
            {isSent === true && (
              <div className="after-subscribe-container">
                <span className="after-subscribe-span">
                  Thank you for subscribing to our mailing list.
                </span>
              </div>
            )}
            {isSent === false && (
              <Form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
                <div
                  className="input-group email-form-input"
                  style={{ position: "relative" }}>
                  <Form.Control
                    {...register("email", {
                      required: "please enter your  email.",
                      pattern: "^[w-.]+@([w-]+.)+[w-]{2,4}$",
                    })}
                    type="email"
                    id="email"
                    className="email-input-text"
                    placeholder="Enter email address"
                  />
                  <div className="mail-btn-div">
                    <button
                      type="submit"
                      className={"px-3  email-inner-submit-btn"}
                      onClick={() => clearErrors("email")}>
                      {isLoading && (
                        <div>
                          <ReactLoading
                            type={"bars"}
                            color={"white"}
                            height=" 21px"
                            width="30px"
                          />
                        </div>
                      )}

                      {!isLoading && (
                        <span className="email-button-text">Subscribe</span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
            <div className="text-danger">
              {errors?.email && <span>{errors.email.message}</span>}{" "}
            </div>
          </div>
        </div>
      </div>
      {currentTheme === "light" && (
        <div>
          <div className=" d-xs-block d-sm-block  d-md-none d-lg-none bottom-section-icon-container">
            <LightBottomSection className="bottom-section-icon"></LightBottomSection>
          </div>

          <div className=" d-xs-block d-sm-block  d-md-none d-lg-none top-section-icon-container">
            <LightTopSection className="top-section-icon"></LightTopSection>
          </div>

          <div className=" d-xs-none d-sm-none  d-md-block d-lg-block left-section-icon-container">
            <LightLeftSection className="left-section-icon"></LightLeftSection>
          </div>

          <div className=" d-xs-none d-sm-none  d-md-block d-lg-block right-section-icon-container">
            <LightRightSection className="right-section-icon"></LightRightSection>
          </div>
        </div>
      )}
      {currentTheme !== "light" && (
        <div>
          <div className=" d-xs-block d-sm-block  d-md-none d-lg-none bottom-section-icon-container">
            <BottomSection className="bottom-section-icon"></BottomSection>
          </div>

          <div className=" d-xs-block d-sm-block  d-md-none d-lg-none top-section-icon-container">
            <TopSection className="top-section-icon"></TopSection>
          </div>

          <div className=" d-xs-none d-sm-none  d-md-block d-lg-block left-section-icon-container">
            <LeftSection className="left-section-icon"></LeftSection>
          </div>

          <div className=" d-xs-none d-sm-none  d-md-block d-lg-block right-section-icon-container">
            <RightSection className="right-section-icon"></RightSection>
          </div>
        </div>
      )}
    </div>
  );
};

export default MailSubscription;
