import * as React from "react";
import Accordion from "react-bootstrap/Accordion";

export default function AllFAQsComponent() {
  var accordionArray = [
    {
      id: 1,
      title: "What is WYSPP?",
      desc: "Wyspp is the go-to platform for staying informed about the world around you. Using verified crowd-sourcing, it provides a wealth of features including local events, global news, hyper-localized lost and found, business ratings, and stock inventory updates. Get informed and connect today with Wyspp's social news toolbox.      ",
    },
    {
      id: 2,
      title: "What is a Watch?",
      desc: 'Users of Watch can report events they observe in their local area that they believe their community should know about. Posts can be viewed globally if their location is followed, but only users within a certain radius can interact with the post by either Shouting (promoting it) or Shushing (suppressing it) depending on its validity. There are no comments on Watch posts, but users can "Spin to Connect" any post to have an open conversation',
    },
    {
      id: 3,
      title: "What is Connect?",
      desc: "Connect offers users the ability to communicate without restrictions, no matter where they are in the world. It encourages dialogue about diverse topics and provides insight into everyday events and issues. Upon submission, media posted will be analyzed and translated by our system so as to ensure accuracy and promote honest discussion. Users have the opportunity to like, comment on, and repost posts as well.",
    },
    {
      id: 4,
      title: "What is Missing?",
      desc: 'This feature serves as a comprehensive "lost and found" service, connecting WYSPP users within a 50-mile radius for items and pets, and throughout the nation for persons. Individuals can post about an item, pet, or person lost, and can even offer a reward for their return.',
    },
    {
      id: 5,
      title: "What is Rating?",
      desc: "Rating lets users rate businesses they visit by assigning 1-5 stars to relevant questions, adding photos and videos to illustrate their answers, and flagging inaccurate information. They can also create new entries if the business hasn't been rated yet.",
    },
    {
      id: 6,
      title: "What is Inventory?",
      desc: "Inventory helps users stay on top of product availability in real-time with its reliable, crowd-sourced local data. Users can look up, track, and update the inventory of items in any store, and even add items not already listed on the app, providing location and availability details to expand the collection.",
    },
    {
      id: 7,
      title: "Is my mobile device supported?",
      desc: "The WYSPP mobile app is supported on the following platforms:\n",
    },
    {
      id: 8,
      title: "How do I get the WYSPP app on my phone?",
      desc: "Downloading WYSPP is quick and simple. Head to the Apple App Store or Google Play store to download and begin Wyspping now! ",
    },
    {
      id: 9,
      title: "Does WYSPP cost money?",
      desc: "WYSPP is completely free! No hidden fees or in-app purchases here. We believe sharing knowledge and happenings in your community should be free and accessible to everyone!",
    },
    {
      id: 10,
      title: "How do I sign up for a Wyspp account?",
      desc: "After downloading the WYSPP Mobile App, you’ll be prompted to create an account as soon as you launch the app. You will also be able to create an account through our web app.",
    },
    {
      id: 11,
      title: "Can I use WYSPP anonymously?",
      desc: "Yes – your security is very important to us. WYSPP is a social news toolbox that encourages non-biased opinions and community teamwork. You can post anonymously in Watch and Connect.  You’ll have the option to go back and change this preference later. For everyone’s safety, your user name will show for Missing posts.",
    },
    {
      id: 12,
      title: "Why isn’t my post showing up?",
      desc: "If your post has either been removed or hasn’t been published, you may be having trouble connecting to the WYSPP app, wifi, or a cellular network. If your connection is strong and your post has still not been published, it may not adhere to our community or safety guidelines.      ",
    },
    {
      id: 13,
      title: "Is the WYSPP mobile app secure?",
      desc: "Yes! We will never share your email, phone number, login information or any other personal information with anyone – ever.      ",
    },
    {
      id: 14,
      title: "Where can I use the WYSPP app?",
      desc: "Simple – anywhere there is Wifi or cellular connection. WYSPP is supported globally.",
    },
    {
      id: 15,
      title:
        "Do I need a WYSPP account to publish a post or to interact with others?",
      desc: "Yes – in order to publish or interact on WYSPP, you’ll need an account. Without an account, you’ll be able to view things others publish but won’t be able to interact or publish for yourself without a WYSPP account.",
    },

    {
      id: 16,
      title: "How do I contact WYSPP if my question isn’t answered here?",
      desc: (
        <p>
          If your question isn’t answered in the FAQs, visit our{" "}
          <a href="/help"> contact page </a> and shoot us your question. We’ll
          do our best to get back to you within 24-48 hours.
        </p>
      ),
    },
  ];
  return (
    <div
      className="container"
      style={{
        marginBottom: "50px",
        paddingLeft: "5%",
      }}>
      <Accordion defaultActiveKey="0">
        {accordionArray.map((d) => (
          <Accordion.Item
            key={d.id}
            style={{
              marginTop: "5px",
              marginBottom: "15px",
              backgroundColor: "rgba(238, 241, 243, 0.1)",
              borderRadius: " 8px",
              border: "none",
            }}
            eventKey={d.id}>
            <Accordion.Header
              style={{
                //backgroundColor: "rgba(238, 241, 243, 0.1)",
                //color: "rgba(238, 241, 243, 0.1)",
                fontWeight: "400",
                fontSize: "25px",
                border: "none",
                borderRadius: " 8px",
              }}>
              {d.title}
            </Accordion.Header>
            <Accordion.Body
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "18px",
                whiteSpace: "pre-line",
              }}>
              {d.desc}
              {d.id === 7 && (
                <ul>
                  <li>iOS 11.0 or later (iPhone, iPod touch, and/or iPad)</li>
                  <li>and Android 5.x.x or later</li>
                </ul>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
