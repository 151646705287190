import LightLogo from "../../../assets/images/logo-light.svg";
import DarkLogo from "../../../assets/images/white-logo.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function GeneralLogo(props) {
  const currentTheme = useSelector((state) => state.auth.theme);
  let navigate = useNavigate();
  return (
    <img
      role="button"
      onClick={() => navigate("/")}
      src={currentTheme === "light" ? LightLogo : DarkLogo}
      className="auth-logo img-fluid"
      alt="logo"
    />
  );
}

export default GeneralLogo;
