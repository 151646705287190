import { axiosInstance } from "../config/network";

const getposts = ({ offset, limit, type, searchText, sort }) => {
  return axiosInstance({
    method: "GET",
    params: {
      limit: limit,
      offset: offset,
      type: type,
      search: searchText,
      sort: sort,
    },
    url: "/post/feed/",
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          posts: res.data.data.results,
          count: res.data.data.count,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.error,
      };
    });
};
export const fetchPostBySlug = async (slug) => {
  return await axiosInstance
    .get("/post/" + slug + "/")
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          postDetail: res.data,
        };
      } else {
        return { status: false, error: res.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.error,
      };
    });
};
const postService = {
  getposts,
  fetchPostBySlug,
};

export default postService;
