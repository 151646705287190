import { userModelIncoming } from "./user";
import { mediaModelIncoming, mediaModelOutcoming } from "./media";
import { userModel } from "./user";
import { commentModelIncoming } from "./comment";

export const postType = {
  wyspp: "wyspp",
  news: "news",
  missing: "missing",
  investigative: "investigative",
  caption: "caption",
  trending_web: "trending",
  all_web: "all",
};
const getPostType = (type) => {
  if (type !== undefined && type !== null) {
    const appType = type.toLowerCase();
    switch (appType) {
      case "wyspp":
        return postType.wyspp;
      case "news":
        return postType.news;
      case "missing":
        return postType.missing;
      case "investigative":
        return postType.investigative;
      case "caption":
        return postType.caption;
      default:
        return postType.wyspp;
    }
  }
  return postType.wyspp;
};

export const postModelIncoming = (post) => {
  const author = post.owner;
  return {
    // id: post.id,
    type: getPostType(post.type),
    slug: post.slug,
    title: post.title,
    discription: post.discription,
    postedAt: post.created_at,
    address: post.address === null ? "" : post.address,
    distance: post.distance === null ? "" : post.distance,
    interactions:
      post.number_interactions === undefined ? 0 : post.number_interactions,
    anonymous: author == null,
    author: author,
    trending: post.is_trending,
    media: post.media_collection,
    bookmarked: post.is_bookmarked,
  };
};

export const postModelOutcoming = (
  title,
  description,
  type,
  postAddress,
  anonymously,
  media,
  url,
  gif,
  privacy,
  externalVideoUrl,
  isFound,
  initialQuestion,
  newTopicName,
  reporterLocation,
  needsCaption,
  speechLanguages
) => {
  let post = {
    geo_location: { lat: 1.2, lng: 1.5 },
    title: title,
    description: description,
    type: type,
    is_anonymous: anonymously,
    media: getMediaCollection(media),
    gif: gif,
    privacy: privacy,
    externalVideoUrl: externalVideoUrl,
    isFound: isFound,
    initialQuestion: initialQuestion,
    newTopicName: newTopicName,
    reporterLocation: reporterLocation,
    needsCaption: needsCaption,
    speechLanguages: speechLanguages,
  };
  return post;
};

const getMediaCollection = (media) => {
  try {
    return media.map((m) => mediaModelOutcoming(m));
  } catch (error) {
    return [];
  }
};

const getComments = (comments) => {
  try {
    return comments.map((c) => commentModelIncoming(c));
  } catch (error) {
    return [];
  }
};

const getMedia = (media) => {
  try {
    const mds = media.map((m) => mediaModelIncoming(m));
    if (mds[0].type == null || mds[0].url == null) return [];
    else return mds;
  } catch (error) {
    return [];
  }
};

export const postModelDetailedIncoming = (post) => {
  const author = post.owner == null ? null : userModel(post.owner);
  return {
    id: post.id,
    type: getPostType(post.type),
    body: post.title,
    rawDesc: post.description === null ? "" : post.description,
    description: post.description,
    createdAt: post.created_at,
    latlng:
      post.location !== undefined && post.location !== null
        ? post.location
        : [],
    address: post.address === null ? "" : post.address,
    distance: post.distance === null ? "" : post.distance,
    interactions:
      post.number_interactions === undefined ? 0 : post.number_interactions,
    anonymous: author == null,
    trending: post.is_trending,
    media: getMedia(post.media_collection),
    url: post.url,
    author: author,
    bookmarked: post.is_bookmarked,
    confirms: post.no_of_confirms != null ? post.no_of_confirms : 0,
    challenges: post.no_of_challenges != null ? post.no_of_challenges : 0,
    confirmed: post.is_confirm,
    comments: getComments(post.comments),
  };
};
