import React, { useEffect,useState,useRef} from "react";
import { useDispatch } from "react-redux";
//import Logo from "../../assets/images/Logo.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GeneralLogo from "../../modules/shared/components/Logo";
import ModeToggle from "../../modules/shared/components/ModeToggle";

function ResponsiveAppBar() {
  
  return (
    <div className="container">
      <Navbar
        className="position-absolute navbar-transparent pt-4  "
       
        style={{
          backgroundColor: "transparent",
          width: "85%",
          top: 0,
        }}
      >
        <GeneralLogo></GeneralLogo>
        {/* <img
          onClick={() => navigate("/")}
          role="button"
          src={Logo}
          className="auth-logo img-fluid"
          alt="logo"
        /> */}
        <Nav className="me-auto"></Nav>

          <ModeToggle></ModeToggle>
        
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
           <Nav>
            <div className="d-grid gap-2 d-md-flex  pt-4">
              <button
                onClick={() => navigate("/login")}
                style={{ width: "120px" }}
                className="btn btn-secondary rounded-pill mr-2 light-blue"
                type="button"
              >
                Sign in
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="btn btn-link"
                type="button"
                style={{
                  textDecoration: "none",
                  color: "white",
                  width: "120px",
                }}
              >
                Sign up
              </button>
            </div>
          </Nav> 
        </Navbar.Collapse>*/}
      </Navbar>
    </div>
  );
}
export default ResponsiveAppBar;
