// import React, { useState, useEffect } from "react";
import introImg from "../../../../../assets/images/introImg.jpeg"; // Import using relative path
import "../../../../../assets/css/home/Landingpage.css";

const VideoIntro = () => {
  return (
    <div className="container ">
      <div className=" row ">
        <div
          className="col-lg-5  justify-content-lg-end col-sm-12 d-flex intro-wisp-img-div
">
          <img
            src={introImg}
            alt="img"
            className="img-fluid  d-block mx-end intro-wisp-img"
          />
        </div>

        <div className="d-flex  justify-content center col-lg-7 col-sm-12  Intro-section-text-div mt-5 mt-lg-0">
          <p className=" Intro-section-text-width">
            /wisp/ 
            <br />
            Frustrated by news networks funded by agendas that serve a chosen
            few, WYSPP was created to give a voice to those looking for unbiased
            and objective information. Get the full story on what's happening
            locally and around the world with Wyspp, a social news toolbox
            designed to keep you informed without being overwhelmed. Our
            verified crowd-sourcing features provide relevant information,
            including local events, global news, hyper-localized lost and found
            listings, business ratings, and stock inventory updates. Stay ahead
            of the curve and connect with Wyspp today!
          </p>
        </div>

        {/* <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="MnUlkXjeJvU"
          onClose={() => setOpen(false)}
        /> */}
      </div>
    </div>
  );
};

export default VideoIntro;
