import { axiosInstance } from "../config/network";

const getInventories = ({
  offset,
  limit,
  category,
  subCategory,
  itemName,
  businessName,
  status,
  sort,
  latitude,
  longitude,
}) => {
  console.log(sort);
  // let UserLocation = JSON.parse(localStorage.getItem("UserLocation"));

  // axiosInstance.interceptors.request.use((config) => {
  //   config.headers["User-Location"] =
  //     UserLocation ?? `${latitude},${longitude}`;
  //   return config;
  // });

  return axiosInstance({
    method: "GET",
    params: {
      limit: limit,
      offset: offset,
      category: category,
      sub_category: subCategory,
      item_name: itemName,
      business_name: businessName,
      status: status,
      sort: sort,
    },
    url: "inventory/feed/",
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          inventories: res.data.data.results,
          count: res.data.data.count,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.error,
      };
    });
};


const getCategories = () => {
  return axiosInstance({
    method: "GET",

    url: "/inventory/categories/",
  }).then((res) => {
    if (res.status === 200 || res.status === 201) {
      return {
        status: true,
        categories: res.data.data,
      };
    } else {
      return { status: false, error: res.data.data.error };
    }
  });
};

const inventoriesService = {
  getInventories,
  getCategories,
};

export default inventoriesService;
