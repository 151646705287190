import React from "react";
import VideoIntro from "./Video";
import Intro from "./Intro";
import Features from "./Features";
import FAQ from "./FAQ";
import { CSSTransition } from "react-transition-group";
import MailSubscription from "./MailSubscription";

const sections = () => {
  return (
    <div>
      <Intro />
      <CSSTransition timeout={400}>
        <VideoIntro />
      </CSSTransition>
      <div className={"features-container"}>
        <Features />
      </div>
      <div className="mail-subscription-container">
        <MailSubscription />
      </div>

      <FAQ />
    </div>
  );
};

export default sections;
