import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { inventoryModelIncoming } from "../models/inventory";
import inventoriesService from "../services/Inventory";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  status: false,
  inventories: [],
  error: "",
  loading: true,
};

export const getInventories = createAsyncThunk(
  "inventory/getinventories",
  async ({
    offset,
    limit,
    category,
    subCategory,
    itemName,
    businessName,
    status,
    sort,
  }) => {
    try {
      const data = await inventoriesService.getInventories({
        offset,
        limit,
        category,
        subCategory,
        itemName,
        businessName,
        status,
        sort,
      });
      //const x = data.inventories.map((i) => inventoryModelIncoming(i));
      return data;
    } catch (error) {
      return { error };
    }
  }
);


export const getCategories = createAsyncThunk(
  "inventory/getCategories",
  async () => {
    try {
      const data = await inventoriesService.getCategories({});

      return data;
    } catch (error) {
      return { error };
    }
  }
);

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  extraReducers: {
    [getInventories.fulfilled]: (state, action) => {
      state.status = true;
      state.inventories.push(action.payload);
      state.error = null;
      state.loading = false;
    },

    [getInventories.rejected]: (state, action) => {
      state.status = true;
      state.inventories = null;
      state.error = action.payload;
    },
  },
});
const { reducer } = inventorySlice;
export default reducer;
